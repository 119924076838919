import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import Auth from "../pages/auth";
import PurchaseOrder from "../pages/sales/PurchaseOrder";
import PurchaseInvoices from "../pages/sales/PurchaseInvoices";
import PurchaseIncoming from "../pages/sales/PurchaseIncoming";
import CreatePurchaseOrder from "../pages/sales/PurchaseOrder/createPurchaseOrder";
import Items from "../pages/sales/Items";
import Suppliers from "../pages/sales/Suppliers/index";
import PurchaseIncomingAdd from "../pages/sales/PurchaseIncoming/Create";
import MySales from "../pages/sales/MySales";
import Debtors from "../pages/sales/Debtors";
import FinishedSales from "../pages/sales/FinishedSales";
import ShippedSales from "../pages/sales/ShippedSales";
import Clients from "../pages/sales/Clients";
import SalesOrder from "../pages/sales/SalesOrder";
import CreateSales from "../pages/sales/SalesOrder/Create";
import Sales from "../pages/sales/Sales";
import OutgoingPayments from "../pages/sales/Payments/OutgoingPayments";
import IncomingPayments from "../pages/sales/Payments/IncomingPayments/index";
import BalancePage from '../pages/sales/Cash/Balance/index';
import SendMoney from '../pages/sales/Cash/SendMoney/index';
const ProtectedRoute = ({ element: Component, ...rest }) => {
	const isAuthenticated = useSelector((state) => state.main.isAuthenticated);
	return isAuthenticated ? <Component {...rest} /> : <Navigate to="/auth" replace />;
};

const RoutesComponent = () => {
	const isAuthenticated = useSelector((state) => state.main.isAuthenticated);

	return (
		<Routes>
			<Route path="/" element={<Navigate to={isAuthenticated ? "/my-sales" : "/auth"} replace />} />
			<Route path="/auth" element={<Auth />} />

			<Route path="/purchase-order" element={<ProtectedRoute element={PurchaseOrder} />} />
			<Route path="/purchase-incoming" element={<ProtectedRoute element={PurchaseIncoming} />} />
			<Route
				path="/purchase-incoming/add"
				element={<ProtectedRoute element={PurchaseIncomingAdd} />}
			/>
			<Route path="/purchase-invoices" element={<ProtectedRoute element={PurchaseInvoices} />} />
			<Route path="/items" element={<ProtectedRoute element={Items} />} />
			<Route path="/suppliers" element={<ProtectedRoute element={Suppliers} />} />

			<Route
				path="/create-purchase-order"
				element={<ProtectedRoute element={CreatePurchaseOrder} />}
			/>
			<Route path="/my-sales" element={<ProtectedRoute element={MySales} />} />
			<Route path="/sales-order" element={<ProtectedRoute element={SalesOrder} />} />
			<Route path="/sales-order/add" element={<ProtectedRoute element={CreateSales} />} />
			<Route path="/debtors" element={<ProtectedRoute element={Debtors} />} />
			<Route path="/finished-sales" element={<ProtectedRoute element={FinishedSales} />} />
			<Route path="/sales" element={<ProtectedRoute element={Sales} />} />

			<Route path="/outgoing-payments" element={<ProtectedRoute element={OutgoingPayments} />} />
			<Route path="/incoming-payments" element={<ProtectedRoute element={IncomingPayments} />} />
			<Route path="/balance" element={<ProtectedRoute element={BalancePage} />} />
			<Route path="/send-money" element={<ProtectedRoute element={SendMoney} />} />

			<Route path="/shipped-sales" element={<ProtectedRoute element={ShippedSales} />} />
			<Route path="/clients" element={<ProtectedRoute element={Clients} />} />
		</Routes>
	);
};

export default RoutesComponent;
