import { Modal, Input, Table, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector } from "react-redux";
const ViewOutgoingPaymentModal = ({
	visible,
	data,
	onClose,
	onClick,
	finish = false,
	incoming = false,
	refetch,
}) => {
	const { t } = useTranslation();
	const role = useSelector((state) => state.main.role);
	const [dueDate, setDueDate] = useState("");

	useEffect(() => {
		if (data?.docDate) {
			setDueDate(moment(data.docDate).format("YYYY-MM-DD"));
		}
	}, [data]);

	if (!data) return null;

	const columns = [
		{
			title: t("cashAcctName"),
			dataIndex: "acctName",
			key: "acctName",
			render: (text, record, index) => {
				return <span>{text}</span>;
				// className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}
			},
		},
		{
			title: t("acctCode"),
			dataIndex: "acctCode",
			key: "acctCode",
			render: (text, record, index) => {
				return <span>{text}</span>;
				// className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}
			},
		},

		{
			title: t("priceSum"),
			dataIndex: "sumApplied",
			key: "sumApplied",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return <span> {formattedText}</span>;
			},
		},
	];

	const tableData = data?.acctCode.map((item, index) => ({
		key: index,
		acctCode: item,
		acctName: data.acctName[index],
		sumApplied: data.sumApplied[index],
	}));

	return (
		<Modal
			title={
				<h1 className="font-nunito text-xl font-extrabold text-[#000000]">
					{t("outgoingPayment")} № {data.docNum}
				</h1>
			}
			visible={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="70%"
			// style={{ height: "100%" }}
			// bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
		>
			<div className="w-full px-4 sm:px-14">
				<div className="w-full">
					<div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
						<div className="flex flex-col gap-3">
							<p className="font-bold">{t("cashAcctName")}</p>
							<Input
								type="text"
								value={data.cashAcctName}
								className="flex h-12 w-[200px] items-center justify-center sm:w-[250px]"
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t("currency")}</p>
							<Input value={data.docCurr} style={{ color: "black" }} disabled />
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t("date")}</p>
							<Input
								type="date"
								value={dueDate}
								style={{ color: "black" }}
								// onChange={(date) => setDueDate(date)}
								disabled
							/>
						</div>
					</div>
					<Table
						columns={columns}
						dataSource={tableData}
						pagination={false}
						className="border-1 mt-12 border-black"
						scroll={{ x: "100%" }}
						bordered
					/>

					<div className="w-full flex items-center justify-between mt-20  ">
						<div className="flex items-center gap-3 ">
							<p className="font-bold">{t("totalForDocument")}:</p>
							<Input
								type="text"
								value={`${data.cashSum} USD`}
								className="flex h-8 w-[70px] items-center justify-center sm:w-[250px]"
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div></div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ViewOutgoingPaymentModal;
