import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Input, Button, message, Select, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CloseOutlined } from "@ant-design/icons";
import { http } from "../../services/http";
import moment from "moment";
import { useSelector } from "react-redux";

const InvoicePaymentModal = ({ visible, onClose, data }) => {
	const { t } = useTranslation();
	const uzsCash = useSelector((state) => state.main.uzsCash);
	const usdCash = useSelector((state) => state.main.usdCash);
	const terminalCash = useSelector((state) => state.main.terminalCash);

	const [loading, setLoading] = useState(false);
	const [docDate, setDocDate] = useState(moment().format("YYYY-MM-DD"));
	const [cashAccount1, setCashAccount1] = useState("");
	const [currency, setCurrency] = useState("");
	const [comment, setComment] = useState("");
	const [amount, setAmount] = useState(data?.openAmount);

	const postData = async () => {
		setLoading(true);
		const cash =
			cashAccount1 === "Cash" && currency === "USD"
				? usdCash
				: cashAccount1 === "Cash" && currency === "UZS"
					? uzsCash
					: cashAccount1 === "Terminal"
						? terminalCash
						: "";

		try {
			const paymentInvoices = [
				{
					lineNum: 0,
					docEntry: data?.docEntry,
					sumApplied: Number(amount),
				},
			];

			await http.post("api/incomingpayments/invoices", {
				cardCode: data?.cardCode,
				docDate: moment(docDate).format("YYYY-MM-DD"),
				cashAccount: cash,
				docCurrency: currency,
				remarks: comment,
				u_DdsArticle: "Доход от реализации",
				paymentInvoices: paymentInvoices,
			});
			message.success(t("sucCreated"));
			setTimeout(() => {
				// resetForm();
				window.location.reload();
			}, 800);
		} catch (error) {
			console.log("Error");
			message.error(t("errCreation") + error.message);
			console.log("Error:", error.message);
		} finally {
			setLoading(false);
		}
	};

	// useEffect(() => {
	// 	setCashAccount("");
	// 	setCurrency("");
	// 	setComment("");
	// 	setAmount("");
	// }, []);

	return (
		<Modal
			title={<h1 className="font-nunito  font-extrabold text-[#000000]">{t("payTitle")}</h1>}
			visible={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="55%"
			style={{ height: "70%" }}
		>
			<div className="w-full px-2 sm:px-14">
				<div className="flex items-center justify-between mt-5">
					<div className="flex flex-col gap-3 ">
						<p className="font-bold">{t("currency")}</p>

						<Select
							type="text"
							onChange={(val) => {
								setCurrency(val);
							}}
							className="flex h-10 w-[250px] items-center justify-center "
						>
							<Select.Option value="USD">USD</Select.Option>
							<Select.Option value="UZS">UZS</Select.Option>
						</Select>
					</div>
					<div className="flex flex-col gap-3">
						<p className="font-bold">{t("date")}</p>
						<div className="flex h-10 w-[130px] items-center justify-center border-[1px] border-[#D0D0D0]">
							<Typography>{moment(docDate).format("DD.MM.YYYY")}</Typography>
						</div>
					</div>
				</div>
				<div className="flex items-center justify-between mt-5">
					<div className=" flex flex-col items-start gap-3  ">
						<p className="font-bold">{t("paymentType")}</p>
						<Select
							type="text"
							onChange={(val) => {
								setCashAccount1(val);
							}}
							className="flex h-10 w-[250px] items-center justify-center"
						>
							<Select.Option value="Cash">{t("cash")}</Select.Option>
							<Select.Option value="Terminal">{t("terminal")}</Select.Option>
						</Select>
					</div>
					<div className="flex flex-col items-start gap-3 ">
						<p className="font-bold">{t("payingAmount")}</p>
						<Input
							type="text"
							onChange={(e) => {
								setAmount(e.target.value);
							}}
							value={amount}
							className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>
				<div className="flex flex-col items-start gap-3 w-full mt-5">
					<p className="font-bold">{t("comments")}</p>
					<TextArea
						onChange={(e) => {
							setComment(e.target.value);
						}}
						className="w-full"
						rows={4}
					/>
				</div>

				<div className="flex items-center gap-10 mt-14 justify-between">
					<Button
						onClick={() => {
							onClose();
						}}
						className="w-full bg-red-600 h-[35px] rounded-lg text-white"
					>
						{t("back")}
					</Button>
					<Button
						onClick={postData}
						loading={loading}
						disabled={loading}
						className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
					>
						{t("pay")}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default InvoicePaymentModal;
