import React, { useState, useEffect, useCallback } from "react";
import { http } from "../../../../services/http";
import { useTranslation } from "react-i18next";
import Layout from "../../../../components/Layout";
import { useSelector } from "react-redux";
import { columnBalance } from "../../../../utils/columns";
import { Table } from "antd";

const BalancePage = () => {
	const { t } = useTranslation();
	const uzsCash = useSelector((state) => state.main.uzsCash);
	const usdCash = useSelector((state) => state.main.usdCash);
	const terminalCash = useSelector((state) => state.main.terminalCash);

	const [fdata, setFData] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);

			const { data } = await http.get(
				`api/cashaccounts?uzsAccount=${uzsCash}&usdAccount=${usdCash}`
			);
			console.log(data);
			setFData(data);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		fetchData();
	}, []);

	const columns = columnBalance(t, true);

	return (
		<Layout>
			<div className="m-5 border-[2px] border-gray-200">
				<div className="flex w-fullitems-center justify-between   ml-4 mr-4 mt-10 sm:ml-10 sm:mr-10 sm:mt-14 ">
					<h1 className="font-poppins text-xl font-bold text-black  sm:text-xl">{t("balance")}</h1>
				</div>
				<div className="mt-8 w-full border-[1px] border-[#E8E8E8] sm:mt-8"></div>

				<div className="m-10">
					<Table
						columns={columns}
						dataSource={fdata}
						pagination={false}
						bordered
						className="w-full"
						rowKey="zakaz"
						rowClassName="text-center"
						sticky={true}
						loading={loading}
					/>
				</div>
			</div>
		</Layout>
	);
};

export default BalancePage;
