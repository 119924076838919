import { Button } from "antd";
import moment from "moment";

export function column(t, getColumnSearchProps, view = true, onView, like = false, onLike) {
	const columns = [
		{
			title: t("numZakaz"),
			dataIndex: "docNum",
			key: "docNum",
			text: "center",

			// ...getColumnSearchProps("docNum"),
		},
		// {
		//   title: t("mijoz"),
		//   dataIndex: "mijoz",
		//   key: "mijoz",
		//   text: 'center',

		//   // ...getColumnSearchProps("mijoz"),
		// },

		{
			title: t("date"),
			dataIndex: "sana",
			key: "sana",
			text: "center",

			// ...getColumnSearchProps("sana"),
		},
		{
			title: t("supplier"),
			dataIndex: "mijoz",
			key: "mijoz",
			text: "center",

			// ...getColumnSearchProps("mijoz"),
		},
		{
			title: t("totalPrice"),
			dataIndex: "summa",
			key: "summa",
			text: "center",

			// ...getColumnSearchProps("summa"),
		},
		{
			title: t("pManager"),
			dataIndex: "slpName",
			key: "slpName",
			text: "center",

			// ...getColumnSearchProps("yaratdi"),
		},
		{
			title: "",
			dataIndex: "action",
			key: "action",
			width: 120,
			text: "center",

			// render: () => (
			// 	<div className="flex items-center gap-5">
			// 		{like ? (
			// 			<button
			// 				onClick={onLike}
			// 				className="bg-[#0A4D68] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
			// 			>
			// 				<svg
			// 					width="22"
			// 					height="20"
			// 					viewBox="0 0 22 20"
			// 					fill="none"
			// 					xmlns="http://www.w3.org/2000/svg"
			// 				>
			// 					<path
			// 						d="M5 6H3C1.89543 6 1 6.89543 1 8V17C1 18.1046 1.89543 19 3 19H5C6.10457 19 7 18.1046 7 17V8C7 6.89543 6.10457 6 5 6Z"
			// 						stroke="white"
			// 						stroke-width="1.5"
			// 						stroke-linecap="round"
			// 						stroke-linejoin="round"
			// 					/>
			// 					<path
			// 						d="M14.8769 19H11.2111C10.4214 19 9.64937 18.7662 8.9923 18.3282L7.4453 17.2969C7.1671 17.1114 7 16.7992 7 16.4648V8.26556C7 8.09154 7.04541 7.92052 7.13176 7.76943L11 1H12.3287C14.3254 1 15.5164 3.22536 14.4088 4.88675L13 7H18.4384C19.7396 7 20.6943 8.22278 20.3787 9.48507L18.7575 15.9701C18.3123 17.7508 16.7124 19 14.8769 19Z"
			// 						stroke="white"
			// 						stroke-width="1.5"
			// 						stroke-linecap="round"
			// 						stroke-linejoin="round"
			// 					/>
			// 				</svg>
			// 			</button>
			// 		) : (
			// 			""
			// 		)}
			// 		{view ? (
			// 			<button
			// 				// onClick={onView}
			// 				className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
			// 			>
			// 				<svg
			// 					width="23"
			// 					height="16"
			// 					viewBox="0 0 22 16"
			// 					fill="none"
			// 					xmlns="http://www.w3.org/2000/svg"
			// 				>
			// 					<path
			// 						d="M20.1303 5.8531C21.2899 7.07317 21.2899 8.92683 20.1303 10.1469C18.1745 12.2047 14.8155 15 11 15C7.18448 15 3.82549 12.2047 1.86971 10.1469C0.710098 8.92683 0.710098 7.07317 1.86971 5.8531C3.82549 3.79533 7.18448 1 11 1C14.8155 1 18.1745 3.79533 20.1303 5.8531Z"
			// 						stroke="white"
			// 						stroke-width="1.5"
			// 					/>
			// 					<path
			// 						d="M14 8C14 9.65685 12.6569 11 11 11C9.34315 11 8 9.65685 8 8C8 6.34315 9.34315 5 11 5C12.6569 5 14 6.34315 14 8Z"
			// 						stroke="white"
			// 						stroke-width="1.5"
			// 					/>
			// 				</svg>
			// 			</button>
			// 		) : (
			// 			""
			// 		)}
			// 	</div>
			// ),
		},
	];

	return columns;
}
export function columnSalesOrder(
	t,
	getColumnSearchProps,
	view = true,
	onView,
	like = false,
	onLike
) {
	const columns = [
		{
			title: t("numZakaz"),
			dataIndex: "docNum",
			key: "docNum",
			text: "center",
			// ...getColumnSearchProps("docNum"),
		},

		{
			title: t("date"),
			dataIndex: "docDueDate",
			key: "docDueDate",
			text: "center",
			// ...getColumnSearchProps("sana"),
		},
		{
			title: t("supplier"),
			dataIndex: "cardName",
			key: "cardName",
			text: "center",
			// ...getColumnSearchProps("mijoz"),
		},
		{
			title: t("totalPrice"),
			dataIndex: "docTotal",
			key: "docTotal",
			text: "center",

			// ...getColumnSearchProps("summa"),
		},
		{
			title: t("pManager"),
			dataIndex: "slpName",
			key: "slpName",
			text: "center",

			// ...getColumnSearchProps("yaratdi"),
		},
		{
			title: "",
			dataIndex: "action",
			key: "action",
			width: 120,
			text: "center",
		},
	];

	return columns;
}

// export function columnSalesOrder(
// 	t,
// 	getColumnSearchProps,
// 	view = true,
// 	onView,
// 	like = false,
// 	onLike
// ) {
// 	const columns = [
// 		{
// 			title: t("numZakaz"),
// 			dataIndex: "docNum",
// 			key: "docNum",
// 			text: "center",
// 			// ...getColumnSearchProps("docNum"),
// 		},

// 		{
// 			title: t("date"),
// 			dataIndex: "docDueDate",
// 			key: "docDueDate",
// 			text: "center",
// 			// ...getColumnSearchProps("sana"),
// 		},
// 		{
// 			title: t("supplier"),
// 			dataIndex: "cardName",
// 			key: "cardName",
// 			text: "center",
// 			// ...getColumnSearchProps("mijoz"),
// 		},
// 		{
// 			title: t("totalPrice"),
// 			dataIndex: "docTotal",
// 			key: "docTotal",
// 			text: "center",

// 			// ...getColumnSearchProps("summa"),
// 		},
// 		{
// 			title: t("pManager"),
// 			dataIndex: "slpName",
// 			key: "slpName",
// 			text: "center",

// 			// ...getColumnSearchProps("yaratdi"),
// 		},
// 		{
// 			title: "",
// 			dataIndex: "action",
// 			key: "action",
// 			width: 120,
// 			text: "center",
// 		},
// 	];

// 	return columns;
// }

export function columnMySales(t, getColumnSearchProps, view = true, onView, like = false, onLike) {
	const columns = [
		{
			title: t("numZakaz"),
			dataIndex: "docNum",
			key: "docNum",
			text: "center",

			// ...getColumnSearchProps("docNum"),
		},
		// {
		//   title: t("mijoz"),
		//   dataIndex: "mijoz",
		//   key: "mijoz",
		//   text: 'center',

		//   // ...getColumnSearchProps("mijoz"),
		// },

		{
			title: t("date"),
			dataIndex: "docDueDate",
			key: "docDueDate",
			text: "center",

			// ...getColumnSearchProps("sana"),
		},
		{
			title: t("supplier"),
			dataIndex: "cardName",
			key: "cardName",
			text: "center",

			// ...getColumnSearchProps("mijoz"),
		},
		{
			title: t("totalPrice"),
			dataIndex: "docTotal",
			key: "docTotal",
			text: "center",

			// ...getColumnSearchProps("summa"),
		},
		{
			title: t("pManager"),
			dataIndex: "slpName",
			key: "slpName",
			text: "center",

			// ...getColumnSearchProps("yaratdi"),
		},
		{
			title: "",
			dataIndex: "action",
			key: "action",
			width: 120,
			text: "center",
		},
	];

	return columns;
}

export function columnItems(t, getColumnSearchProps, like = true, view = true, onView, onLike) {
	const columns = [
		{
			title: t("itemDocNum"),
			dataIndex: "itemCode",
			key: "itemCode",
			text: "center",

			// ...getColumnSearchProps("docNum"),
		},
		// {
		//   title: t("mijoz"),
		//   dataIndex: "mijoz",
		//   key: "mijoz",
		//   text: 'center',

		//   // ...getColumnSearchProps("mijoz"),
		// },

		{
			title: t("itemName"),
			dataIndex: "itemName",
			key: "itemName",
			text: "center",
			width: 150,

			// ...getColumnSearchProps("sana"),
		},
		{
			title: t("width"),
			dataIndex: "width",
			key: "width",
			text: "center",

			// ...getColumnSearchProps("mijoz"),
		},
		{
			title: t("height"),
			dataIndex: "height",
			key: "height",
			text: "center",

			// ...getColumnSearchProps("summa"),
		},
		{
			title: t("quantityN"),
			dataIndex: "onHandSum",
			key: "onHandSum",
			text: "center",

			// ...getColumnSearchProps("yaratdi"),
		},
		// {
		// 	title: t("whs"),
		// 	dataIndex: "whsName",
		// 	key: "whsName",
		// 	text: "center",

		// 	// ...getColumnSearchProps("yaratdi"),
		// },
		{
			title: "",
			dataIndex: "action",
			key: "action",
			width: 120,
			text: "center",

			render: () => (
				<div className="flex items-center gap-5">
					{like ? (
						<button className="bg-[#0A4D68] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center">
							<svg
								width="22"
								height="20"
								viewBox="0 0 22 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M5 6H3C1.89543 6 1 6.89543 1 8V17C1 18.1046 1.89543 19 3 19H5C6.10457 19 7 18.1046 7 17V8C7 6.89543 6.10457 6 5 6Z"
									stroke="white"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M14.8769 19H11.2111C10.4214 19 9.64937 18.7662 8.9923 18.3282L7.4453 17.2969C7.1671 17.1114 7 16.7992 7 16.4648V8.26556C7 8.09154 7.04541 7.92052 7.13176 7.76943L11 1H12.3287C14.3254 1 15.5164 3.22536 14.4088 4.88675L13 7H18.4384C19.7396 7 20.6943 8.22278 20.3787 9.48507L18.7575 15.9701C18.3123 17.7508 16.7124 19 14.8769 19Z"
									stroke="white"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</button>
					) : (
						""
					)}
					{view ? (
						<button className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center">
							<svg
								width="23"
								height="16"
								viewBox="0 0 22 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M20.1303 5.8531C21.2899 7.07317 21.2899 8.92683 20.1303 10.1469C18.1745 12.2047 14.8155 15 11 15C7.18448 15 3.82549 12.2047 1.86971 10.1469C0.710098 8.92683 0.710098 7.07317 1.86971 5.8531C3.82549 3.79533 7.18448 1 11 1C14.8155 1 18.1745 3.79533 20.1303 5.8531Z"
									stroke="white"
									stroke-width="1.5"
								/>
								<path
									d="M14 8C14 9.65685 12.6569 11 11 11C9.34315 11 8 9.65685 8 8C8 6.34315 9.34315 5 11 5C12.6569 5 14 6.34315 14 8Z"
									stroke="white"
									stroke-width="1.5"
								/>
							</svg>
						</button>
					) : (
						""
					)}
				</div>
			),
		},
	];

	return columns;
}

export function columnSupplier(t, getColumnSearchProps, like = true, view = true, onView, onLike) {
	const columns = [
		{
			title: t("supplierDocNum"),
			dataIndex: "cardCode",
			key: "cardCode",
			text: "center",

			// ...getColumnSearchProps("docNum"),
		},
		// {
		//   title: t("mijoz"),
		//   dataIndex: "mijoz",
		//   key: "mijoz",
		//   text: 'center',

		//   // ...getColumnSearchProps("mijoz"),
		// },

		{
			title: t("itemName"),
			dataIndex: "cardName",
			key: "cardName",
			text: "center",
			width: 150,

			// ...getColumnSearchProps("sana"),
		},

		{
			title: t("currency"),
			dataIndex: "currency",
			key: "currency",
			text: "center",

			// ...getColumnSearchProps("summa"),
		},
		{
			title: t("balanceOrder"),
			dataIndex: "balance",
			key: "balance",
			text: "center",

			// ...getColumnSearchProps("yaratdi"),
		},
		{
			title: t("balanceTotal"),
			dataIndex: "ordersBal",
			key: "ordersBal",
			text: "center",

			// ...getColumnSearchProps("yaratdi"),
		},
		{
			title: "",
			dataIndex: "action",
			key: "action",
			width: 120,
			text: "center",

			render: () => (
				<div className="flex items-center gap-5">
					{like ? (
						<button
							// onClick={onLike}
							className="bg-[#0A4D68] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
						>
							<svg
								width="22"
								height="20"
								viewBox="0 0 22 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M5 6H3C1.89543 6 1 6.89543 1 8V17C1 18.1046 1.89543 19 3 19H5C6.10457 19 7 18.1046 7 17V8C7 6.89543 6.10457 6 5 6Z"
									stroke="white"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M14.8769 19H11.2111C10.4214 19 9.64937 18.7662 8.9923 18.3282L7.4453 17.2969C7.1671 17.1114 7 16.7992 7 16.4648V8.26556C7 8.09154 7.04541 7.92052 7.13176 7.76943L11 1H12.3287C14.3254 1 15.5164 3.22536 14.4088 4.88675L13 7H18.4384C19.7396 7 20.6943 8.22278 20.3787 9.48507L18.7575 15.9701C18.3123 17.7508 16.7124 19 14.8769 19Z"
									stroke="white"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</button>
					) : (
						""
					)}
					{view ? (
						<button
							// onClick={onView}
							className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
						>
							<svg
								width="23"
								height="16"
								viewBox="0 0 22 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M20.1303 5.8531C21.2899 7.07317 21.2899 8.92683 20.1303 10.1469C18.1745 12.2047 14.8155 15 11 15C7.18448 15 3.82549 12.2047 1.86971 10.1469C0.710098 8.92683 0.710098 7.07317 1.86971 5.8531C3.82549 3.79533 7.18448 1 11 1C14.8155 1 18.1745 3.79533 20.1303 5.8531Z"
									stroke="white"
									stroke-width="1.5"
								/>
								<path
									d="M14 8C14 9.65685 12.6569 11 11 11C9.34315 11 8 9.65685 8 8C8 6.34315 9.34315 5 11 5C12.6569 5 14 6.34315 14 8Z"
									stroke="white"
									stroke-width="1.5"
								/>
							</svg>
						</button>
					) : (
						""
					)}
				</div>
			),
		},
	];

	return columns;
}

export function column5(t, getColumnSearchProps) {
	const columns = [
		{
			title: t("mijoz"),
			dataIndex: "mijoz",
			key: "mijoz",
			// ...getColumnSearchProps("mijoz"),
		},
		{
			title: t("numZakaz"),
			dataIndex: "docNum",
			key: "docNum",
			// ...getColumnSearchProps("docNum"),
		},
		{
			title: t("date"),
			dataIndex: "sana",
			key: "sana",
			// ...getColumnSearchProps("sana"),
		},
		{
			title: t("priceSum"),
			dataIndex: "summa",
			key: "summa",
			// ...getColumnSearchProps("summa"),
		},
		{
			title: t("pManager"),
			dataIndex: "yaratdi",
			key: "yaratdi",
			// ...getColumnSearchProps("yaratdi"),
		},
	];

	return columns;
}

export function column2(t, getColumnSearchProps, driver = false, action = false) {
	const columns = [
		{
			title: t("mijoz"),
			dataIndex: "mijoz",
			key: "mijoz",
			// ...getColumnSearchProps("mijoz"),
		},
		{
			title: t("numZakaz"),
			dataIndex: "docNum",
			key: "docNum",
			// ...getColumnSearchProps("docNum"),
		},
		{
			title: t("date"),
			dataIndex: "sana",
			key: "sana",
			// ...getColumnSearchProps("sana"),
		},
		{
			title: t("priceSum"),
			dataIndex: "summa",
			key: "summa",
		},
		{
			title: t("pManager"),
			dataIndex: "yaratdi",
			key: "yaratdi",
			// ...getColumnSearchProps("yaratdi"),
		},
	];

	if (driver) {
		columns.push({
			title: t("driver"),
			dataIndex: "driver",
			key: "driver",
		});
	}
	if (action) {
		columns.push({
			title: t("ship"),
			key: "action",
			render: () => <Button className="bg-[#0A4D68] text-white">{t("ship")}</Button>,
		});
	}

	return columns;
}

export function column3(t, getColumnSearchProps, driver = false, remove = false, onClick) {
	const columns = [
		{
			title: t("mijoz"),
			dataIndex: "mijoz",
			key: "mijoz",
			// ...getColumnSearchProps("mijoz"),
		},
		{
			title: t("numZakaz"),
			dataIndex: "docNum",
			key: "docNum",
			// ...getColumnSearchProps("docNum"),
		},
		{
			title: t("date"),
			dataIndex: "sana",
			key: "sana",
			// ...getColumnSearchProps("sana"),
		},
		{
			title: t("priceSum"),
			dataIndex: "summa",
			key: "summa",
		},
		{
			title: t("pManager"),
			dataIndex: "yaratdi",
			key: "yaratdi",
			// ...getColumnSearchProps("yaratdi"),
		},
		{
			title: t("Status"),
			dataIndex: "docStatus",
			key: "docStatus",
			// ...getColumnSearchProps("docStatus"),

			// render: () => <Button className="bg-[#0A4D68] text-white">{t("remove")}</Button>,
		},
	];

	if (driver) {
		columns.push({
			title: t("driver"),
			dataIndex: "driver",
			key: "driver",
		});
	}

	return columns;
}
export function columnClient(
	t,
	getColumnSearchProps,
	remove = true,
	like = true,
	view = true,
	onView,
	onLike,
	onClick
) {
	const columns = [
		{
			title: t("name"),
			dataIndex: "cardName",
			key: "cardName",
			// ...getColumnSearchProps("mijoz"),
		},
		{
			title: t("Type"),
			dataIndex: "type",
			key: "type",
			// ...getColumnSearchProps("docNum"),
		},
		{
			title: t("phoneNum"),
			dataIndex: "Cellular",
			key: "Cellular",
			// ...getColumnSearchProps("sana"),
		},
		{
			title: t("Address"),
			dataIndex: "address",
			key: "address",
		},
		{
			title: "",
			dataIndex: "action",
			key: "action",
			width: 120,
			text: "center",

			render: () => (
				<div className="flex items-center gap-5">
					{view ? (
						<button
							// onClick={onView}
							className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
						>
							<svg
								width="23"
								height="16"
								viewBox="0 0 22 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M20.1303 5.8531C21.2899 7.07317 21.2899 8.92683 20.1303 10.1469C18.1745 12.2047 14.8155 15 11 15C7.18448 15 3.82549 12.2047 1.86971 10.1469C0.710098 8.92683 0.710098 7.07317 1.86971 5.8531C3.82549 3.79533 7.18448 1 11 1C14.8155 1 18.1745 3.79533 20.1303 5.8531Z"
									stroke="white"
									stroke-width="1.5"
								/>
								<path
									d="M14 8C14 9.65685 12.6569 11 11 11C9.34315 11 8 9.65685 8 8C8 6.34315 9.34315 5 11 5C12.6569 5 14 6.34315 14 8Z"
									stroke="white"
									stroke-width="1.5"
								/>
							</svg>
						</button>
					) : (
						""
					)}
				</div>
			),
		},
	];

	return columns;
}

export function columnOutgoingPayment(
	t,
	getColumnSearchProps,
	view = true,
	onView,
	like = false,
	onLike
) {
	const columns = [
		{
			title: t("numZakaz"),
			dataIndex: "docNum",
			key: "docNum",
			text: "center",
			// ...getColumnSearchProps("docNum"),
		},

		{
			title: t("cashAcctName"),
			dataIndex: "cashAcctName",
			key: "cashAcctName",
			text: "center",
			// ...getColumnSearchProps("docNum"),
		},

		{
			title: t("date2"),
			dataIndex: "docDate",
			key: "docDate",
			text: "center",
			// ...getColumnSearchProps("sana"),
		},
		{
			title: t("totalPrice"),
			dataIndex: "cashSum",
			key: "cashSum",
			text: "center",
			// ...getColumnSearchProps("mijoz"),
		},
		{
			title: t("comments"),
			dataIndex: "comments",
			key: "comments",
			text: "center",

			// ...getColumnSearchProps("summa"),
		},

		{
			title: "",
			dataIndex: "action",
			key: "action",
			width: 120,
			text: "center",
		},
	];

	return columns;
}

export function columnIncomingPayment(
	t,
	getColumnSearchProps,
	view = true,
	onView,
	like = false,
	onLike
) {
	const columns = [
		{
			title: t("numZakaz"),
			dataIndex: "incomingPaymentDocNum",
			key: "incomingPaymentDocNum",
			text: "center",
			// ...getColumnSearchProps("docNum"),
		},

		{
			title: t("Client"),
			dataIndex: "cardName",
			key: "cardName",
			text: "center",
			// ...getColumnSearchProps("docNum"),
		},
		{
			title: t("cashAcctName"),
			dataIndex: "acctName",
			key: "acctName",
			text: "center",
			// ...getColumnSearchProps("docNum"),
		},

		{
			title: t("date2"),
			dataIndex: "incomingPaymentDocDate",
			key: "incomingPaymentDocDate",
			text: "center",
			render: (text, record) => {
				return `${moment(text).format("DD.MM.YYYY")}`;
			},
			// ...getColumnSearchProps("sana"),
		},
		{
			title: t("totalPrice"),
			dataIndex: "cashSum",
			key: "cashSum",
			text: "center",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return `${formattedText} ${record.docCurr}`;
			},
			// ...getColumnSearchProps("mijoz"),
		},
		{
			title: t("comments"),
			dataIndex: "comments",
			key: "comments",
			text: "center",

			// ...getColumnSearchProps("summa"),
		},

		{
			title: "",
			dataIndex: "action",
			key: "action",
			width: 120,
			text: "center",
		},
	];

	return columns;
}

export function columnBalance(t, getColumnSearchProps, view = true, onView, like = false, onLike) {
	const columns = [
		{
			title: t("acctCode"),
			dataIndex: "acctCode",
			key: "acctCode",
			text: "center",
			// ...getColumnSearchProps("docNum"),
		},
		{
			title: t("cashAcctName"),
			dataIndex: "acctName",
			key: "acctName",
			text: "center",
			// ...getColumnSearchProps("docNum"),
		},

		{
			title: t("totalPrice"),
			dataIndex: "currTotal",
			key: "currTotal",
			text: "center",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return `${formattedText}`;
			},
			// ...getColumnSearchProps("mijoz"),
		},
		{
			title: t("currency"),
			dataIndex: "actCurr",
			key: "actCurr",
			text: "center",

			// ...getColumnSearchProps("summa"),
		},
	];

	return columns;
}
