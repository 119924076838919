import React from "react";
import Layout from "../../../../components/Layout/index";

const PurchaseIncomingAdd = () => {
  return (
    <Layout>
      <div>PurchaseIncomingAdd</div>
    </Layout>
  );
};

export default PurchaseIncomingAdd;
