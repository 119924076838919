import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	login,
	setToken,
	setEmployeeId,
	setRole,
	setSalesPersonCode,
	setSelectedPath,
	setTerminalCash,
	setUzsCash,
	setUsdCash,
} from "../slice/mainSlice";
import { Form, Input, Typography, Button, message, Spin } from "antd";
import { session } from "../services/session";
import { http } from "../services/http";
import { useTranslation } from "react-i18next";

const Auth = () => {
	const { t } = useTranslation();
	const emailRef = useRef(null);
	const passwordRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [isMeLoading, setMeLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const { data } = await http.get("api/auth/me");
				if (data) {
					const {
						accessToken: token,
						firstName,
						lastName,
						jobTitle: role,
						employeeId,
						salesPersonCode,
						u_KassirTerminal: terminalCash,
						u_KassirKassa_UZS: uzsCash,
						u_KassirKassa_USD: usdCash,
					} = data;
					const user = `${firstName} ${lastName}`;
					dispatch(login(user));
					dispatch(setToken(token));
					dispatch(setRole(role));
					dispatch(setEmployeeId(employeeId));
					dispatch(setSalesPersonCode(salesPersonCode));
					dispatch(setTerminalCash(terminalCash));
					dispatch(setUzsCash(uzsCash));
					dispatch(setUsdCash(usdCash));

					const prevLocation = sessionStorage.getItem("prevLocation");
					const extractedData = prevLocation.slice(1);
					if (role === "Wrhmanager") {
						navigate(prevLocation || "/purchase-order");
						dispatch(setSelectedPath(extractedData || "purchase-order"));
					} else if (role === "Sales Manager") {
						navigate(prevLocation || "/my-sales");
						dispatch(setSelectedPath(extractedData || "my-sales"));
					} else if (role === "Supplier") {
						navigate(prevLocation || "/purchase-order");
						dispatch(setSelectedPath(extractedData || "purchase-order"));
					} else if (role === "Cashier") {
						navigate(prevLocation || "/sales-order");
						dispatch(setSelectedPath(extractedData || "sales-order"));
					}
				}
			} catch (err) {
				console.log(err);
			} finally {
				setMeLoading(false);
			}
		};
		fetchData();
	}, [dispatch, navigate]);

	const handleSubmit = async (values) => {
		const { email, password } = values;
		setLoading(true);
		try {
			const { data } = await http.get(
				`api/auth?EmployeeCode=${email}&ExternalEmployeeNumber=${password}`
			);
			const {
				accessToken: token,
				firstName,
				lastName,
				jobTitle: role,
				employeeId,
				salesPersonCode,
				u_KassirTerminal: terminalCash,
				u_KassirKassa_UZS: uzsCash,
				u_KassirKassa_USD: usdCash,
			} = data;
			const user = `${firstName} ${lastName}`;

			if (
				role === "Supplier" ||
				role === "Wrhmanager" ||
				role === "Sales Manager" ||
				role === "Cashier"
			) {
				session.set(token);
				localStorage.setItem("employeeId", employeeId);
				localStorage.setItem("salesPersonCode", salesPersonCode);
				localStorage.setItem("role", role);

				dispatch(login(user));
				dispatch(setToken(token));
				dispatch(setRole(role));
				dispatch(setEmployeeId(employeeId));
				dispatch(setSalesPersonCode(salesPersonCode));
				dispatch(setTerminalCash(terminalCash));
				dispatch(setUzsCash(uzsCash));
				dispatch(setUsdCash(usdCash));
				// role === "Supplier" ||
				// role === "Wrhmanager" ||
				// role === "Sales Manager" ||
				// role === "Cashier"
				message.success(t("successMessage", { user }));
				if (role === "Wrhmanager") {
					navigate("/purchase-order");
					dispatch(setSelectedPath("purchase-order"));
				} else if (role === "Sales Manager") {
					navigate("/my-sales");
					dispatch(setSelectedPath("my-sales"));
				} else if (role === "Supplier") {
					navigate("/purchase-order");
					dispatch(setSelectedPath("purchase-order"));
				} else if (role === "Cashier") {
					navigate("/sales-order");
					dispatch(setSelectedPath("sales-order"));
				}
			} else {
				message.error(t("accessDenied"));
			}
		} catch (err) {
			message.error(t("userNotFound"));
		} finally {
			setLoading(false);
		}
	};

	if (isMeLoading) {
		return (
			<div className="flex h-screen w-full items-center justify-center">
				<Spin size="large" />
			</div>
		);
	}

	return (
		<div className="flex h-screen w-full items-center justify-center">
			<div className="flex w-[400px] items-center justify-center rounded-3xl bg-gradient-to-br from-[#05BFDB] to-[#0A4D68]">
				<Form onFinish={handleSubmit} className="flex flex-col items-center gap-10">
					<Typography className="font-montserrat mt-14 text-3xl font-bold text-white">
						{t("Authorization")}
					</Typography>
					<Form.Item name="email" rules={[{ required: true, message: t("emailRequired") }]}>
						<div className="flex flex-col gap-4">
							<h3 className="font-montserrat text-base font-bold text-white">{t("Login")}</h3>
							<Input
								ref={emailRef}
								type="text"
								autoFocus
								className="h-[50px] w-[280px] pl-5 custom rounded-lg"
							/>
						</div>
					</Form.Item>
					<Form.Item name="password" rules={[{ required: true, message: t("passwordRequired") }]}>
						<div className="flex flex-col gap-4">
							<h3 className="font-montserrat text-base font-bold text-white">{t("Password")}</h3>
							<Input.Password
								ref={passwordRef}
								required
								className="h-[50px] w-[280px] rounded-lg pl-5 custom"
							/>
						</div>
					</Form.Item>
					<div className="mb-10">
						<Button
							type="primary"
							htmlType="submit"
							loading={loading}
							className="font-montserrat h-[50px] w-[280px] rounded-2xl bg-white text-base font-bold text-[#0A4D68]"
						>
							{t("SignIn")}
						</Button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default Auth;
