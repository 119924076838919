import moment from "moment";

export const aggregateDocuments = (data) => {
	const aggregatedData = data.data.reduce((acc, entry) => {
		entry.documentLines.forEach((line) => {
			const key = `${entry.docEntry}`;
			if (!acc[key]) {
				acc[key] = {
					docEntry: entry.docEntry,
					docNum: entry.docNum,
					cardCode: entry.cardCode,
					mijoz: entry.cardName,
					sana: moment(entry.docDueDate).format("DD-MM-YYYY"),
					docDueDate: entry.docDueDate,
					summa: entry.docTotal,
					ownerCode: entry.ownerCode,
					docTotalQuantity: entry.docTotalQuantity,
					salesPersonCode: entry.salesPersonCode,
					yaratdi: entry.slpName,
					docCur: entry.docCur,
					comments: entry.comments,
					u_BonusTotal: entry.u_BonusTotal,
					slpName: entry.slpName,
					itemCode: [],
					lineNum: [],
					lineStatus: [],
					maxsulot: [],
					baseType: [],
					baseEntry: [],
					baseLine: [],
					quantity: [],
					whsCode: [],
					whsName: [],
					measureUnit: [],
					price: [],
					inventoryQuantity: [],
					discountPercent: [],
					lineTotal: [],
					currency: [],
					openInvQty: [],
					openQty: [],
					ugpName: [],
					u_BonusPerQty: [],
					u_BonusSumm: [],
					u_Category: [],
					u_ItemGroup: [],
					width: [],
					height: [],
				};
			}
			acc[key].itemCode.push(line.itemCode);
			acc[key].lineStatus.push(line.lineStatus);
			acc[key].maxsulot.push(line.itemDescription);
			acc[key].baseType.push(line.baseType);
			acc[key].baseEntry.push(line.baseEntry);
			acc[key].baseLine.push(line.baseLine);
			acc[key].quantity.push(line.quantity);
			acc[key].whsCode.push(line.warehouseCode);
			acc[key].whsName.push(line.whsName);
			acc[key].lineNum.push(line.lineNum);
			acc[key].measureUnit.push(line.measureUnit);
			acc[key].price.push(line.price);
			acc[key].inventoryQuantity.push(line.inventoryQuantity);
			acc[key].discountPercent.push(line.discountPercent);
			acc[key].lineTotal.push(line.lineTotal);
			acc[key].currency.push(line.currency);
			acc[key].openInvQty.push(line.openInvQty);
			acc[key].openQty.push(line.openQty);
			acc[key].ugpName.push(line.ugpCode);
			acc[key].u_BonusPerQty.push(line.u_BonusPerQty);
			acc[key].u_Category.push(line.u_Category);
			acc[key].width.push(line.width);
			acc[key].height.push(line.height);
			acc[key].u_ItemGroup.push(line.u_ItemGroup);
			acc[key].u_BonusSumm.push(line.u_BonusSumm);
		});
		return acc;
	}, {});

	const aggregatedArray = Object.values(aggregatedData);

	aggregatedArray.sort((a, b) => b.docEntry - a.docEntry);

	return aggregatedArray;
};

export const aggregateDocumentsWithDriver = (data) => {
	const aggregatedData = data.data.reduce((acc, entry) => {
		entry.documentLines.forEach((line) => {
			const key = `${entry.docEntry}`;
			if (!acc[key]) {
				acc[key] = {
					docEntry: entry.docEntry,
					docNum: entry.docNum,
					cardCode: entry.cardCode,
					mijoz: entry.cardName,
					sana: entry.docDueDate,
					docDueDate: entry.docDueDate,
					summa: entry.docTotal,
					ownerCode: entry.ownerCode,
					salesPersonCode: entry.salesPersonCode,
					docTotalQuantity: entry.docTotalQuantity,
					driver: `${entry.driverFirstName} ${entry.driverLastname}`,
					yaratdi: entry.slpName,
					docCur: entry.docCur,
					comments: entry.comments,
					u_FuraNum: entry.u_FuraNum,
					u_InvoiceNum: entry.u_InvoiceNum,
					u_BonusTotal: entry.u_BonusTotal,
					docStatus: entry.docStatus,
					slpName: entry.slpName,
					itemCode: [],
					maxsulot: [],
					baseType: [],
					baseEntry: [],
					lineNum: [],
					baseLine: [],
					quantity: [],
					whsCode: [],
					lineStatus: [],
					whsName: [],
					measureUnit: [],
					price: [],
					lineTotal: [],
					currency: [],
					openInvQty: [],
					openQty: [],
					ugpName: [],

					width: [],
					height: [],
				};
			}
			acc[key].itemCode.push(line.itemCode);
			acc[key].lineStatus.push(line.lineStatus);
			acc[key].maxsulot.push(line.itemDescription);
			acc[key].baseType.push(line.baseType);
			acc[key].baseEntry.push(line.baseEntry);
			acc[key].baseLine.push(line.baseLine);
			acc[key].lineNum.push(line.lineNum);
			acc[key].quantity.push(line.quantity);
			acc[key].whsCode.push(line.warehouseCode);
			acc[key].whsName.push(line.whsName);
			acc[key].measureUnit.push(line.measureUnit);
			acc[key].price.push(line.price);

			acc[key].lineTotal.push(line.lineTotal);
			acc[key].currency.push(line.currency);
			acc[key].openInvQty.push(line.openInvQty);
			acc[key].ugpName.push(line.ugpCode);
			acc[key].openQty.push(line.openQty);
			acc[key].width.push(line.width);
			acc[key].height.push(line.height);
		});
		return acc;
	}, {});

	const aggregatedArray = Object.values(aggregatedData);

	aggregatedArray.sort((a, b) => b.docEntry - a.docEntry);

	return aggregatedArray;
};

export const mappedDataSalesOrder = (data) => {
	const aggregatedData = data.data.reduce((acc, entry) => {
		entry.documentLines.forEach((line) => {
			const key = `${entry.docEntry}`;
			if (!acc[key]) {
				acc[key] = {
					docEntry: entry.docEntry,
					docNum: entry.docNum,
					cardCode: entry.cardCode,
					cardName: entry.cardName,
					docTotal: entry.docTotal,
					docTotalQuantity: entry.docTotalQuantity,
					ownerCode: entry.ownerCode,
					docDueDate: entry.docDueDate,
					salesPersonCode: entry.salesPersonCode,
					slpName: entry.slpName,
					comments: entry.comments,
					docStatus: entry.docStatus,
					docCur: entry.docCur,
					openAmount: entry.openAmount,
					itemCode: [],
					itemDescription: [],
					currency: [],
					lineNum: [],
					quantity: [],
					warehouseCode: [],
					whsName: [],
					price: [],
					lineTotal: [],
					ugpCode: [],
					openQty: [],
				};
			}
			acc[key].itemCode.push(line.itemCode);
			acc[key].itemDescription.push(line.itemDescription);
			acc[key].currency.push(line.currency);
			acc[key].quantity.push(line.quantity);
			acc[key].warehouseCode.push(line.warehouseCode);
			acc[key].lineNum.push(line.lineNum);
			acc[key].whsName.push(line.whsName);
			acc[key].price.push(line.price);
			acc[key].lineTotal.push(line.lineTotal);
			acc[key].ugpCode.push(line.ugpCode);
			acc[key].openQty.push(line.openQty);
		});
		return acc;
	}, {});

	const aggregatedArray = Object.values(aggregatedData);

	aggregatedArray.sort((a, b) => b.docEntry - a.docEntry);

	return aggregatedArray;
};

export const mappedDataOutgoingPayments = (data) => {
	const aggregatedData = data.data.reduce((acc, entry) => {
		entry.paymentAccounts.forEach((line) => {
			const key = `${entry.docNum}`;
			if (!acc[key]) {
				acc[key] = {
					docNum: entry.docNum,
					docDate: entry.docDate,
					cashAcctName: entry.cashAcctName,
					cashAcct: entry.cashAcct,
					cashSum: entry.cashSum,
					comments: entry.comments,
					docCurr: entry.docCurr,
					u_DdsArticle: entry.u_DdsArticle,
					acctCode: [],
					acctName: [],
					sumApplied: [],
				};
			}
			acc[key].acctCode.push(line.acctCode);
			acc[key].acctName.push(line.acctName);
			acc[key].sumApplied.push(line.sumApplied);
		});
		return acc;
	}, {});

	const aggregatedArray = Object.values(aggregatedData);

	aggregatedArray.sort((a, b) => b.docNum - a.docNum);

	return aggregatedArray;
};

export const mappedDataIncomingPayments = (data) => {
	const aggregatedData = data.data.reduce((acc, entry) => {
		entry.paymentInvoices.forEach((line) => {
			const key = `${entry.incomingPaymentDocNum}`;
			if (!acc[key]) {
				acc[key] = {
					incomingPaymentDocNum: entry.incomingPaymentDocNum,
					incomingPaymentDocDate: moment(entry.incomingPaymentDocDate).format("DD.MM.YYYY"),
					acctName: entry.acctName,
					cashAcct: entry.cashAcct,
					cashSum: entry.cashSum,
					comments: entry.comments,
					docCurr: entry.docCurr,
					u_DdsArticle: entry.u_DdsArticle,
					cardName: entry.cardName,
					docType: [],
					docDateRow: [],
					invoiceDocNum: [],
					sumApplied: [],
					docTotal: [],
				};
			}
			acc[key].docType.push(line.docType);
			acc[key].docDateRow.push(line.docDateRow);
			acc[key].invoiceDocNum.push(line.invoiceDocNum);
			acc[key].docTotal.push(line.docTotal);
			acc[key].sumApplied.push(line.sumApplied);
		});
		return acc;
	}, {});

	const aggregatedArray = Object.values(aggregatedData);

	aggregatedArray.sort((a, b) => b.incomingPaymentDocNum - a.incomingPaymentDocNum);

	return aggregatedArray;
};
