import { Modal, Input, Table, message, Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { http } from "../../services/http";
import moment from "moment";

const ViewSuppliersModal = ({ visible, data, onClose, onClick, finish = false }) => {
	const { t } = useTranslation();
	const employeeId = localStorage.getItem("employeeId");
	const salesPersonCode = localStorage.getItem("salesPersonCode");

	const [loading, setLoading] = useState(false);

	if (!data) return null;

	// const columns = [
	// 	{
	// 		title: t("item"),
	// 		dataIndex: "item",
	// 		key: "item",
	// 		width: 150,
	// 		render: (text, record, index) => {
	// 			return (
	// 				<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
	// 					{text}
	// 				</span>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		title: t("width"),
	// 		dataIndex: "width",
	// 		key: "width",
	// 		render: (text, record, index) => {
	// 			const formattedText = new Intl.NumberFormat("fr-FR").format(text);
	// 			return (
	// 				<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
	// 					{formattedText}
	// 				</span>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		title: t("height"),
	// 		dataIndex: "height",
	// 		key: "height",
	// 		render: (text, record, index) => {
	// 			const formattedText = new Intl.NumberFormat("fr-FR").format(text);
	// 			return (
	// 				<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
	// 					{formattedText}
	// 				</span>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		title: t("quantityN"),
	// 		dataIndex: "quantity",
	// 		key: "quantity",
	// 		render: (text, record, index) => {
	// 			const formattedText = new Intl.NumberFormat("fr-FR").format(text);
	// 			return (
	// 				<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
	// 					{formattedText}
	// 				</span>
	// 			);
	// 		},
	// 	},
	// 	{
	// 		title: t("kv.m"),
	// 		dataIndex: "ugpName",
	// 		key: "ugpName",
	// 		render: (text, record, index) => {
	// 			return (
	// 				<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
	// 					{text}
	// 				</span>
	// 			);
	// 		},
	// 	},

	// 	{
	// 		title: t("price"),
	// 		dataIndex: "price",
	// 		key: "price",
	// 		render: (text, record, index) => {
	// 			const formattedText = new Intl.NumberFormat("fr-FR").format(text);
	// 			return (
	// 				<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
	// 					{formattedText} USD
	// 				</span>
	// 			);
	// 		},
	// 	},

	// 	{
	// 		title: t("totalMiqdor"),
	// 		dataIndex: "lineTotal",
	// 		key: "lineTotal",
	// 		render: (text, record, index) => {
	// 			const formattedText = new Intl.NumberFormat("fr-FR").format(text);
	// 			return (
	// 				<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
	// 					{formattedText} USD
	// 				</span>
	// 			);
	// 		},
	// 	},
	// ];

	// const tableData = data.maxsulot.map((item, index) => ({
	// 	key: index,
	// 	maxsulot: item,
	// 	quantity: data.quantity[index],
	// 	measureUnit: data.measureUnit[index],
	// 	price: data.price[index],
	// 	inventoryQuantity: data.inventoryQuantity[index],
	// 	lineTotal: data.lineTotal[index],
	// 	ugpName: data.ugpName[index],
	// 	whsName: data.whsName[index],
	// 	u_BonusPerQty: data.u_BonusPerQty[index],
	// 	u_BonusSumm: data.u_BonusSumm[index],
	// 	u_Category: data.u_Category[index],
	// 	u_ItemGroup: data.u_ItemGroup[index],
	// }));

	// const tableData = data?.maxsulot.map((item, index) => ({
	// 	key: index,
	// 	u_Category: data.u_Category[index],
	// 	u_ItemGroup: data.u_ItemGroup[index],
	// 	item: item,
	// 	quantity: data.quantity[index],
	// 	price: data.price[index],
	// 	lineTotal: data.lineTotal[index],
	// 	whsName: data.whsName[index],
	// 	ugpName: data.ugpName[index],
	// 	width: data.width[index],
	// 	height: data.height[index],
	// }));

	// const postData = async () => {
	// 	setLoading(true);

	// 	try {
	// 		const documentLines = data.maxsulot.map((item, index) => ({
	// 			itemCode: data.itemCode[index],
	// 			quantity: data.quantity[index],
	// 			price: data.price[index],
	// 			baseEntry: data.docEntry,
	// 			baseLine: data.lineNum[index],
	// 			currency: data.currency[index],
	// 		}));

	// 		await http.post("api/purchase/haridni-yakunlash", {
	// 			cardCode: String(data.cardCode),
	// 			salesPersonCode: Number(salesPersonCode),
	// 			documentsOwner: Number(employeeId),
	// 			documentLines: documentLines,
	// 			docCurrency: data.currency[0],
	// 			docDate: moment(data.sana, "DD-MM-YYYY").format("YYYY-MM-DD"),
	// 			docDueDate: moment(data.sana, "DD-MM-YYYY").format("YYYY-MM-DD"),
	// 		});
	// 		message.success(t("sucCreated"));

	// 		setTimeout(() => {
	// 			// resetForm();
	// 			onClose();
	// 		}, 800);
	// 	} catch (error) {
	// 		console.log("Error");
	// 		message.error(t("errCreation") + error.message);
	// 		console.log("Error:", error.message);
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	return (
		<Modal
			title={<h1 className="font-nunito text-xl font-extrabold text-[#000000]">{t("productCard")}</h1>}
			visible={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="60%"
			style={{ height: "80%" }}
			bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
		>
			<div className="w-full px-4 sm:px-5">
				<div className="w-full">
					<div className="mt-10 flex flex-col items-center gap-7  sm:flex-row">
						<div className="flex flex-col gap-3">
							<p className="font-nunito">{t("supplierName")}</p>
							<Input
								type="text"
								value={data.cardName}
								className="flex h-10  w-[200px] items-center justify-center "
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-nunito">{t("Address")} </p>
							<Input
								value={data.address}
								className="flex h-10  w-[200px] items-center justify-center "
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-nunito">{t("phoneNum")}</p>
							<Input
								value={data.Cellular}
								className="flex h-10  w-[200px] items-center justify-center "
								style={{ color: "black" }}
								disabled
							/>
						</div>
					</div>

					<div className="mt-10 flex flex-col items-center gap-7  sm:flex-row">
						<div className="flex flex-col gap-3">
							<p className="font-nunito">{t("Saldo")}</p>
							<Input
								type="text"
								value={data.balance}
								className="flex h-10  w-[200px] items-center justify-center "
								style={{ color: "black" }}
								disabled
							/>
						</div>
					</div>

					{/* <Table
						columns={columns}
						dataSource={tableData}
						pagination={false}
						className="border-1 mt-12 border-black"
						scroll={{ x: "100%" }}
						bordered
					/> */}

					{/* <div className="w-full flex items-center justify-between">
						<div>{""}</div>
						<div className="mt-20  flex items-center gap-3 ">
							<p className="font-nunito">Всего по документу:</p>
							<Input
								type="text"
								value={`${data.BWdth1} USD`}
								className="flex h-8 w-[70px] items-center justify-center sm:w-[250px]"
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-20  flex items-center gap-3 ">
							<p className="font-nunito">Всего по документу:</p>
							<Input
								type="text"
								value={`${data.BHeight1} USD`}
								className="flex h-8 w-[70px] items-center justify-center sm:w-[250px]"
								style={{ color: "black" }}
								disabled
							/>
						</div>
					</div> */}
				</div>
			</div>
		</Modal>
	);
};

export default ViewSuppliersModal;
