import { Modal, Input, Table, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector } from "react-redux";
import InvoicePaymentModal from "./invoicePaymentModal";
const ViewSalesOrderModal = ({
	visible,
	data,
	onClose,
	onClick,
	finish = false,
	incoming = false,
	refetch,
}) => {
	const { t } = useTranslation();
	const role = useSelector((state) => state.main.role);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [dueDate, setDueDate] = useState("");

	useEffect(() => {
		if (data?.docDueDate) {
			setDueDate(moment(data.docDueDate).format("YYYY-MM-DD"));
		}
	}, [data]);

	if (!data) return null;

	const columns = [
		{
			title: t("item"),
			dataIndex: "itemDescription",
			key: "itemDescription",
			width: 150,
			render: (text, record, index) => {
				return <span>{text}</span>;
				// className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}
			},
		},

		{
			title: t("quantityN"),
			dataIndex: "quantity",
			key: "quantity",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return <span> {formattedText}</span>;
			},
		},
		{
			title: t("kv.m"),
			dataIndex: "ugpCode",
			key: "ugpCode",
		},

		{
			title: t("price"),
			dataIndex: "price",
			key: "price",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return <span>{formattedText} USD</span>;
			},
		},

		{
			title: t("totalMiqdor"),
			dataIndex: "lineTotal",
			key: "lineTotal",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return <span>{formattedText} USD</span>;
			},
		},
	];

	const tableData = data?.itemDescription.map((item, index) => ({
		key: index,
		itemDescription: item,
		quantity: data.quantity[index],
		price: data.price[index],
		lineTotal: data.lineTotal[index],
		whsName: data.whsName[index],
		ugpCode: data.ugpCode[index],
	}));

	return (
		<Modal
			title={
				<h1 className="font-nunito text-xl font-extrabold text-[#000000]">
					{t("order")} № {data.docNum}
				</h1>
			}
			visible={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="90%"
			// style={{ height: "100%" }}
			// bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
		>
			<div className="w-full px-4 sm:px-14">
				<div className="w-full">
					<div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
						<div className="flex flex-col gap-3">
							<p className="font-bold">{t("mijoz")}</p>
							<Input
								type="text"
								value={data.cardName}
								className="flex h-12 w-[200px] items-center justify-center sm:w-[250px]"
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t("currency")}</p>
							<Input value={data.currency[0]} style={{ color: "black" }} disabled />
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t("date")}</p>
							<Input
								type="date"
								value={dueDate}
								style={{ color: "black" }}
								onChange={(date) => setDueDate(date)}
							/>
						</div>
					</div>
					<Table
						columns={columns}
						dataSource={tableData}
						pagination={false}
						className="border-1 mt-12 border-black"
						scroll={{ x: "100%" }}
						bordered
					/>

					<div className="w-full flex items-center justify-between mt-20  ">
						<div className="flex items-center gap-3 ">
							<p className="font-bold">{t("totalForDocument")}:</p>
							<Input
								type="text"
								value={`${data.docTotal} USD`}
								className="flex h-8 w-[70px] items-center justify-center sm:w-[250px]"
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div>
							{role === "Cashier" ? (
								<Button
									onClick={() => {
										setIsModalVisible(true);
									}}
									className="h-[30px] w-[140px]  bg-[#0A4D68] rounded-lg text-white "
								>
									{t("pay")}
								</Button>
							) : (
								""
							)}
						</div>
					</div>
				</div>
				<InvoicePaymentModal
					visible={isModalVisible}
					data={data}
					onClose={() => setIsModalVisible(false)}
				/>
			</div>
		</Modal>
	);
};

export default ViewSalesOrderModal;
