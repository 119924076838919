import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Input, DatePicker } from "antd";
import { http } from "../../../services/http";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import Header from "../../../components/header";
import { columnClient } from "../../../utils/columns";
import moment from "moment";
import { getToday } from "../../../utils/getDays";
import Layout from "../../../components/Layout";
import ViewSalesModal from "../../../components/Modal/viewSalesModal";
import { useNavigate } from "react-router-dom";
const { RangePicker } = DatePicker;

const Debtors = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const role = localStorage.getItem("role");

	const [fdata, setFData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(0);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedRowData, setSelectedRowData] = useState(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const formattedDate = getToday();

	const [docNum, setDocNum] = useState("");
	const [cardName, setCardName] = useState("");
	const [slpName, setSlpName] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState(formattedDate);

	const fetchData = useCallback(
		async (page, ScardName, SgroupName) => {
			try {
				let url = " ";
				setLoading(true);

				url = `api/businesspartners/debetors?cardName=${ScardName}&groupName=${SgroupName}&pageSize=10&pageToken=${page}`;
				const { data } = await http.get(url);

				const hasMore = data.length === 10 ? true : false;

				setFData(data);
				setHasMoreData(hasMore);
			} catch (error) {
				console.log(error);
			}
			setLoading(false);
		},
		[cardName, docNum, startDate, endDate, slpName]
	);

	useEffect(() => {
		fetchData(currentPage, cardName, docNum, slpName, startDate, endDate);
	}, [fetchData, currentPage, cardName, docNum, slpName, startDate, endDate]);

	const handleNextPage = () => {
		if (hasMoreData) {
			setCurrentPage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1);
		}
	};

	const handleRowClick = (record) => {
		setSelectedRowData(record);
		setIsModalVisible(true);
	};

	let searchInput = null;

	const handleDateRangeChange = (dates, confirm, dataIndex) => {
		debouncedSearch(dates, dataIndex, confirm);
	};

	const handleStartDate = (e) => {
		setStartDate(e.target.value);
	};

	const handleEndDate = (e) => {
		setEndDate(e.target.value);
	};

	const debouncedSearch = useMemo(
		() =>
			debounce((value, dataIndex, confirm) => {
				if (dataIndex === "mijoz") {
					if (value) {
						setCardName(value);
					} else {
						setCardName("");
					}
				} else if (dataIndex === "docNum") {
					if (value) {
						setDocNum(value);
					} else {
						setDocNum("");
					}
				} else if (dataIndex === "yaratdi") {
					if (value) {
						setSlpName(value.toUpperCase());
					} else {
						setSlpName("");
					}
				} else if (dataIndex === "sana") {
					if (value) {
						const formattedStartDate = new Date(value[0]).toLocaleString();
						const formattedEndDate = new Date(value[1]).toLocaleString();
						console.log("formattedStartDate : " + formattedStartDate);
						console.log("formattedEndDate : " + formattedEndDate);

						if (formattedStartDate && formattedEndDate) {
							setStartDate(moment(formattedStartDate).format("YYYY-MM-DD"));
							setEndDate(moment(formattedEndDate).format("YYYY-MM-DD"));
						}
					} else {
						setStartDate("");
						setEndDate("");
					}
				} else {
				}
				confirm();
			}, 2000),
		[fetchData, currentPage]
	);

	const getColumnSearchProps = (dataIndex) => {
		if (dataIndex === "sana") {
			return {
				filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
					<div style={{ padding: 8 }}>
						<RangePicker
							value={selectedKeys && selectedKeys[0] ? selectedKeys : []}
							onChange={(dates) => {
								setSelectedKeys(dates ? dates : []);
								handleDateRangeChange(dates, confirm, dataIndex);
							}}
							style={{ marginBottom: 8, display: "block" }}
							disabledDate={(current) => current && current > moment().endOf("day")}
						/>
					</div>
				),
				filterIcon: (filtered) => (
					<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
				),
				onFilter: (value, record) => {
					if (Array.isArray(value) && value.length === 2) {
						const [startDate, endDate] = value;
						const recordDate = moment(record[dataIndex]);
						return recordDate.isBetween(startDate, endDate, "[]");
					}
					return true;
				},
				render: (text) => text,
			};
		}
		return {
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
				<div style={{ padding: 8 }}>
					<Input
						ref={(node) => {
							searchInput = node;
						}}
						placeholder={`${t("search")} ${dataIndex}`}
						value={selectedKeys[0]}
						onChange={(e) => {
							setSelectedKeys(e.target.value ? [e.target.value] : []);
							handleSearch(e, confirm, dataIndex);
						}}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
				</div>
			),
			filterIcon: (filtered) => (
				<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
			),
			onFilter: (value, record) =>
				record[dataIndex]
					? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
					: "",
			onFilterDropdownOpenChange: (visible) => {
				if (visible) {
					setTimeout(() => searchInput.select(), 100);
				}
			},
			render: (text) =>
				searchedColumn === dataIndex ? (
					<Highlighter
						highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
						searchWords={[searchText]}
						autoEscape
						textToHighlight={text ? text.toString() : ""}
					/>
				) : (
					text
				),
		};
	};
	const handleSearch = (e, confirm, dataIndex) => {
		const value = e.target.value;
		debouncedSearch(value, dataIndex, confirm);
	};
	const columns = columnClient(t, getColumnSearchProps, true).map((col) => {
		return col;
	});
	return (
		<Layout>
			<Header
				title={"Accounts receivable"}
				currentPage={currentPage + 1}
				handlePreviousPage={handlePreviousPage}
				handleNextPage={handleNextPage}
				hasMoreData={hasMoreData}
				columns={columns}
				fdata={fdata}
				loading={loading}
			/>
			<ViewSalesModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => setIsModalVisible(false)}
				{...(role === "Wrhmanager" ? { incoming: true } : {})}
			/>
		</Layout>
	);
};

export default Debtors;
