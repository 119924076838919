import { Modal, Input, Button, message } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { http } from "../../services/http";

const CreteUserModal = ({ visible, onClose }) => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [name, setName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [address, setAddress] = useState("");

	const postData = async () => {
		setLoading(true);

		try {
			await http.post("api/businesspartners/yetkazib-beruvchilar", {
				cardName: name,
				phoneNumber,
				address,
			});
			message.success(t("sucCreated"));

			setTimeout(() => {
				// resetForm();
				onClose();
			}, 800);
		} catch (error) {
			console.log("Error");
			message.error(t("errCreation") + error.message);
			console.log("Error:", error.message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal
			title={<h1 className="font-nunito  font-extrabold text-[#000000]">{t("addSupplier")}</h1>}
			visible={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="40%"
			style={{ height: "70%" }}
			bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
		>
			<div className="w-full px-2 sm:px-14">
				<div className="mt-4 flex flex-col gap-3 sm:mt-5">
					<p>{t("supplierName")}</p>
					<Input
						type="text"
						onChange={(e) => {
							setName(e.target.value);
						}}
						className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
					/>
				</div>
				<div className="mt-4 flex flex-col gap-3 sm:mt-5">
					<p>{t("phoneNum")}</p>
					<Input
						type="text"
						onChange={(e) => {
							setPhoneNumber(e.target.value);
						}}
						className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
					/>
				</div>
				<div className="mt-4 flex flex-col gap-3 sm:mt-5">
					<p>{t("Address")}</p>
					<Input
						type="text"
						onChange={(e) => {
							setAddress(e.target.value);
						}}
						className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
					/>
				</div>

				<div className="flex items-center gap-10 mt-14 justify-between">
					<Button
						onClick={() => {
							onClose();
						}}
						className="w-full bg-red-600 h-[35px] rounded-lg text-white"
					>
						{t("cancel")}
					</Button>
					<Button
						onClick={postData}
						loading={loading}
						disabled={loading}
						className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
					>
						{t("add")}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default CreteUserModal;
