import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Input, Button, message, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CloseOutlined } from "@ant-design/icons";
import { http } from "../../../../../services/http";
import { useSelector } from "react-redux";
import moment from "moment";

const CreateIncomingPaymentModal = ({ visible, onClose }) => {
	const { t } = useTranslation();
	const uzsCash = useSelector((state) => state.main.uzsCash);
	const usdCash = useSelector((state) => state.main.usdCash);

	const [loading, setLoading] = useState(false);
	const [dds, setDds] = useState("");
	const [currency, setCurrency] = useState("");
	const [docDate, setDocDate] = useState(moment().format("YYYY-MM-DD"));
	const [comment, setComment] = useState("");
	const [cardCode, setCardCode] = useState("");
	const [bpData, setBpData] = useState([]);
	const [ddsData, setDdsData] = useState([]);
	const [amount, setAmount] = useState("");

	useEffect(() => {
		fetchBp();
		fetchDds();
	}, []);

	const postData = async () => {
		setLoading(true);
		try {
			await http.post("api/incomingpayments", {
				cardCode: cardCode,
				docDate: moment(docDate).format("YYYY-MM-DD"),
				cashAccount: currency === "USD" ? usdCash : currency === "UZS" ? uzsCash : "",
				docCurrency: currency,
				cashSum: Number(amount),
				remarks: comment,
				u_DdsArticle: dds,
			});
			message.success(t("sucCreated"));
			setTimeout(() => {
				// resetForm();
				onClose();
			}, 800);
		} catch (error) {
			console.log("Error");
			message.error(t("errCreation") + error.message);
			console.log("Error:", error.message);
		} finally {
			setLoading(false);
		}
	};

	const fetchBp = useCallback(async () => {
		try {
			const { data } = await http.get("api/businesspartners/mijozlar");

			setBpData(data);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	}, []);

	const fetchDds = useCallback(async () => {
		try {
			const { data } = await http.get("api/incomingpayments/dds-articles");

			setDdsData(data);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	}, []);

	return (
		<Modal
			title={<h1 className="font-nunito  font-extrabold text-[#000000]">{t("createIncoming")}</h1>}
			visible={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="65%"
			style={{ height: "70%" }}
		>
			<div className="w-full px-2 sm:px-14">
				<div className="flex items-center gap-10 justify-between mt-5">
					<div className=" flex flex-col items-start gap-3 w-full ">
						<p className="font-bold">{t("Client")}</p>
						<Select
							type="text"
							onChange={(val) => {
								setCardCode(val);
							}}
							className="flex h-10 w-full items-center justify-center"
						>
							{bpData.map((cash, idx) => (
								<Select.Option key={idx} value={cash.cardCode}>
									{`${cash.cardName}`}
								</Select.Option>
							))}
						</Select>
					</div>

					<div className="flex flex-col gap-3 w-full">
						<p className="font-bold">{t("currency")}</p>

						<Select
							type="text"
							onChange={(val) => {
								setCurrency(val);
							}}
							className="flex h-10 w-full items-center justify-center "
						>
							<Select.Option value={"USD"}>USD</Select.Option>
							<Select.Option value={"UZS"}>UZS</Select.Option>
						</Select>
					</div>
					<div className="flex flex-col items-start gap-3 w-full">
						<p className="font-bold">{t("date")}</p>
						<Input
							type="date"
							onChange={(e) => {
								setDocDate(e.target.value);
							}}
							value={docDate}
							className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>
				<div className="flex items-center  gap-10 justify-between mt-5">
					<div className=" flex flex-col items-start gap-3  w-full">
						<p className="font-bold">{t("dds")}</p>
						<Select
							type="text"
							onChange={(val) => {
								setDds(val);
							}}
							className="flex h-10 w-full items-center justify-center"
						>
							{ddsData.map((dds, idx) => (
								<Select.Option key={idx} value={dds.fldValue}>
									{dds.descr}
								</Select.Option>
							))}
						</Select>
					</div>
					<div className="flex flex-col items-start gap-3 w-full">
						<p className="font-bold">{t("payingAmount")}</p>
						<Input
							type="text"
							onChange={(e) => {
								setAmount(e.target.value);
							}}
							value={amount}
							className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>
				<div className="flex flex-col items-start gap-3 w-full mt-5">
					<p className="font-bold">{t("comments")}</p>
					<TextArea
						onChange={(e) => {
							setComment(e.target.value);
						}}
						className="w-full"
						rows={4}
					/>
				</div>

				<div className="flex items-center gap-10 mt-14 justify-between">
					<Button
						onClick={() => {
							onClose();
						}}
						className="w-full bg-red-600 h-[35px] rounded-lg text-white"
					>
						{t("back")}
					</Button>
					<Button
						onClick={postData}
						loading={loading}
						disabled={loading}
						className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
					>
						{t("add")}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default CreateIncomingPaymentModal;
