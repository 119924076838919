import React, { useState, useEffect } from "react";
import Layout from "../../../../components/Layout/index";
import { Select, Input, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import CreateUserModal from "../../../../components/Modal/createUserModal";
import { http } from "../../../../services/http";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function CreatePurchaseOrder() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const employeeId = localStorage.getItem("employeeId");
	const salesPersonCode = localStorage.getItem("salesPersonCode");

	const initItem = {
		ItemCode: "",
		width: "",
		height: "",
		ugpName: "",
		priceQ: 0,
		priceKvm: 0,
		quantity: "",
		U_Kvmetr: "",
	};

	const [items, setItems] = useState([{ ...initItem }]);
	const [loading, setLoading] = useState(false);

	const [cardCode, setCardCode] = useState("");
	const [currency, setCurrency] = useState("");
	const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [ddata, setDdata] = useState([]);
	const [wdata, setWdata] = useState([]);

	const handleAddItem = () => {
		setItems((prev) => [...prev, { ...initItem }]);
	};

	const handleRemoveItem = (i) => {
		setItems((prev) => prev.filter((_, idx) => idx !== i));
	};

	useEffect(() => {
		fetchSuppliers();
		fetchItems();
	}, []);

	const fetchSuppliers = async () => {
		try {
			const { data } = await http.get(`api/businesspartners/yetkazib-beruvchilar`);
			const formattedData = data.data.map((item) => ({
				name: item.cardName,
				code: item.cardCode,
			}));
			setDdata(formattedData);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchItems = async () => {
		try {
			const { data } = await http.get(`api/items/get-items`);
			const formattedData = data.data.map((item) => ({
				itemName: item.itemName,
				itemCode: item.itemCode,
				price: item.price,
				currency: item.currency,
				ugpName: item.ugpName,
				u_ItemGroup: item.u_ItemGroup,
				U_Kvmetr: item.U_Kvmetr,
				onHand: item.onHand,
				whsName: item.whsName,
				width: item.BWdth1,
				height: item.BHeight1,
			}));
			setWdata(formattedData);
		} catch (err) {
			console.log(err);
		}
	};

	const handleItemChange = (value, i) => {
		const selectedItem = wdata.find((item) => item.itemCode === value);
		const updatedItems = [...items];
		updatedItems[i] = {
			...updatedItems[i],
			ItemCode: selectedItem.itemCode,
			width: selectedItem.width,
			height: selectedItem.height,
			ugpName: selectedItem.ugpName,
			U_Kvmetr: selectedItem.U_Kvmetr,
		};
		setItems(updatedItems);
	};

	const handleInputChange = (e, i, field) => {
		const updatedItems = [...items];
		updatedItems[i][field] = e.target.value;
		setItems(updatedItems);
	};

	const postData = async () => {
		setLoading(true);

		try {
			const documentLines = items.map((item, index) => ({
				itemCode: item.ItemCode,
				quantity: item.quantity,
				price: item.priceQ > 0 ? item.priceQ : item.priceKvm > 0 ? item.priceKvm : 0,
				currency: currency,
			}));

			await http.post("api/purchase/xarid-uchun-buyurtma", {
				cardCode: cardCode,
				salesPersonCode: Number(salesPersonCode),
				documentsOwner: Number(employeeId),
				docDate: date,
				docDueDate: date,
				docCurrency: currency,
				documentLines: documentLines,
			});
			message.success(t("sucCreated"));
			setTimeout(() => {
				navigate(-1);
			}, 800);
		} catch (error) {
			console.log("Error");
			message.error(t("errCreation") + error.message);
			console.log("Error:", error.message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Layout>
			<div className="m-5 flex items-center gap-5">
				<div className="flex flex-col gap-2">
					<p>{t("suppliers")}</p>
					<div className="flex">
						<Select
							value={cardCode}
							onChange={(value) => setCardCode(value)}
							className="w-[250px]"
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().includes(input.toLowerCase())
							}
						>
							{ddata.map((supplier) => (
								<Select.Option key={supplier.code} value={supplier.code}>
									{supplier.name}
								</Select.Option>
							))}
						</Select>
						<button
							className="w-[30px] h-[30px] bg-blue-700 text-white text-lg"
							onClick={() => setIsModalVisible(true)}
						>
							+
						</button>
					</div>
				</div>
				<div className="flex flex-col gap-2">
					<p>{t("currency")}</p>
					<div className="flex">
						<Select value={currency} onChange={(value) => setCurrency(value)} className="w-[150px]">
							<Select.Option key={1} value={"USD"}>
								USD
							</Select.Option>
						</Select>
					</div>
				</div>
				<div className="flex flex-col gap-2">
					<p>{t("date2")}</p>
					<div className="flex">
						<Input
							className="w-[150px]"
							type="date"
							value={date}
							onChange={(e) => setDate(e.target.value)}
						/>
					</div>
				</div>
			</div>
			<div className="overflow-y-auto my-8 ">
				<table className="w-full text-sm m-5 text-left rtl:text-right text-gray-500">
					<thead className="text-xs text-gray-700 uppercase bg-gray-50">
						<tr>
							<th className="px-6 py-4 border text-center">{t("item")}</th>
							<th className="px-6 py-4 border text-center">{t("width")}</th>
							<th className="px-6 py-4 border text-center">{t("height")}</th>
							<th className="px-6 py-4 border text-center">{t("quantityN")}</th>
							<th className="px-6 py-4 border text-center">{t("kv.m")}</th>
							<th className="px-6 py-4 border text-center">{t("pricePerKv")}</th>
							<th className="px-6 py-4 border text-center">{t("pricePerQuanity")}</th>
							<th className="px-6 py-4 border text-center">{t("totalSale")}</th>
							<th className="px-6 py-4 border text-center"></th>
						</tr>
					</thead>
					<tbody>
						{items.map((v, i) => (
							<tr className="bg-white hover:bg-gray-50 items-center" key={i}>
								<td className="px-6 border py-4">
									<Select
										value={v.ItemCode}
										onChange={(value) => handleItemChange(value, i)}
										className="w-[200px]"
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) =>
											option.children.toLowerCase().includes(input.toLowerCase())
										}
									>
										{wdata.map((supplier) => (
											<Select.Option key={supplier.itemCode} value={supplier.itemCode}>
												{supplier.itemName}
											</Select.Option>
										))}
									</Select>
								</td>
								<td className="px-6 border py-4">
									<Input
										className="w-[80px]"
										type="number"
										placeholder={t("width")}
										value={v.width}
										disabled
									/>
								</td>
								<td className="px-6 border py-4">
									<Input
										className="w-[80px]"
										type="number"
										placeholder={t("height")}
										value={v.height}
										disabled
									/>
								</td>
								<td className="px-6 border py-4">
									<Input
										className="w-[80px]"
										type="text"
										placeholder={t("quantityN")}
										value={v.quantity}
										onChange={(e) => handleInputChange(e, i, "quantity")}
									/>
								</td>
								<td className="px-6 border py-4">
									<Input
										className="w-[80px]"
										type="text"
										placeholder={t("kv.m")}
										value={v.quantity * v.U_Kvmetr}
										disabled
									/>
								</td>
								<td className="px-6 border py-4">
									<Input
										className="w-[80px]"
										type="text"
										placeholder="0"
										value={v.priceKvm}
										onChange={(e) => handleInputChange(e, i, "priceKvm")}
										disabled={v.priceQ > 0}
									/>
								</td>
								<td className="px-6 border py-4">
									<Input
										className="w-[80px]"
										type="text"
										placeholder="0"
										value={v.priceQ}
										onChange={(e) => handleInputChange(e, i, "priceQ")}
										disabled={v.priceKvm > 0}
									/>
								</td>
								<td className="px-6 border py-4">
									<Input
										className="w-[80px]"
										type="text"
										placeholder={t("totalSale")}
										value={
											v.priceQ > 0
												? v.quantity * v.priceQ
												: v.priceKvm > 0
													? v.quantity * v.U_Kvmetr * v.priceKvm
													: 0
										}
										disabled
									/>
								</td>
								<td className="px-6 border py-4">
									<div className="flex items-center gap-2">
										{items.length > 1 && (
											<Button
												type="danger"
												onClick={() => handleRemoveItem(i)}
												className="bg-red-600 text-white"
											>
												-
											</Button>
										)}
										{i === items.length - 1 && (
											<Button type="primary" onClick={handleAddItem}>
												+
											</Button>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="flex items-center justify-end mt-10 mb-10 mr-10">
				<div className="flex items-center gap-10">
					<Button
						onClick={() => navigate(-1)}
						className="h-[30px] w-[140px]  bg-red-600 rounded-lg text-white "
					>
						{t("back")}
					</Button>
					<Button
						onClick={postData}
						loading={loading}
						disabled={loading || cardCode === "" || currency === ""}
						className="h-[30px] w-[140px]  bg-[#0A4D68] rounded-lg text-white "
					>
						{t("save")}
					</Button>
				</div>
				<CreateUserModal
					visible={isModalVisible}
					data={null}
					onClose={() => {
						fetchSuppliers();
						setIsModalVisible(false);
					}}
				/>
			</div>
		</Layout>
	);
}
