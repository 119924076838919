import React from "react";
import Menubar  from '../Menu';
import Navbar from '../navbar';

const Layout = ({ children }) => {
	return (
		<div className="flex h-full w-full overflow-y-auto">
    <Menubar />
    <div className="h-screen w-full overflow-y-auto">
    <Navbar />
    {children}
    </div>
		</div>
	);
};

export default Layout;
