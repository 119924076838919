import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	UserOutlined,
	TableOutlined,
} from "@ant-design/icons";
import { setCollapse, setSelectedPath, setOpenMiniMenu } from "../../slice/mainSlice";
import logo from "../../assets/images/BIS logo.svg";
import "../../assets/style/menu.css";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SalesIcon from "../../assets/icons/SalesSvg";
import PaymentIcon from "../../assets/icons/PaymentSvg";
import ClientIcon from "../../assets/icons/ClientSvg";
import SalesStaticsIcon from "../../assets/icons/SalesStatics";

export const Menubar = () => {
	const { t } = useTranslation();
	const { collapse, selectedPath, openMiniMenu } = useSelector((state) => state.main);
	const role = localStorage.getItem("role");
	const dispatch = useDispatch();
	const [menuHeight, setMenuHeight] = useState("auto");
	const navigate = useNavigate();

	const pages = {
		Wrhmanager: [
			{
				key: "purchase-order",
				label: t("purchase-order"),
				icon: (
					<svg
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18.75 8.75H11.25C9.86929 8.75 8.75 9.86929 8.75 11.25V18.75C8.75 20.1307 9.86929 21.25 11.25 21.25H18.75C20.1307 21.25 21.25 20.1307 21.25 18.75V11.25C21.25 9.86929 20.1307 8.75 18.75 8.75Z"
							stroke="white"
							strokeWidth="1.5"
						/>
						<path
							d="M16.25 12.5H13.75"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M27.5 15C27.5 21.9035 21.9035 27.5 15 27.5C10.9104 27.5 7.2796 25.5361 4.99909 22.5M2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C19.0895 2.5 22.7204 4.46389 25.0009 7.5"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
						<path d="M5 22.5L3.75 26.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
						<path d="M25 7.5L26.25 3.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
					</svg>
				),
			},
			{
				key: "purchase-incoming",
				label: t("purchase-incoming"),
				icon: (
					<svg
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18.75 8.75H11.25C9.86929 8.75 8.75 9.86929 8.75 11.25V18.75C8.75 20.1307 9.86929 21.25 11.25 21.25H18.75C20.1307 21.25 21.25 20.1307 21.25 18.75V11.25C21.25 9.86929 20.1307 8.75 18.75 8.75Z"
							stroke="white"
							strokeWidth="1.5"
						/>
						<path
							d="M16.25 12.5H13.75"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M27.5 15C27.5 21.9035 21.9035 27.5 15 27.5C10.9104 27.5 7.2796 25.5361 4.99909 22.5M2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C19.0895 2.5 22.7204 4.46389 25.0009 7.5"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
						<path d="M5 22.5L3.75 26.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
						<path d="M25 7.5L26.25 3.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
					</svg>
				),
			},
			{
				key: "purchase-invoices",
				label: t("purchase-invoices"),
				icon: (
					<svg
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18.75 8.75H11.25C9.86929 8.75 8.75 9.86929 8.75 11.25V18.75C8.75 20.1307 9.86929 21.25 11.25 21.25H18.75C20.1307 21.25 21.25 20.1307 21.25 18.75V11.25C21.25 9.86929 20.1307 8.75 18.75 8.75Z"
							stroke="white"
							strokeWidth="1.5"
						/>
						<path
							d="M16.25 12.5H13.75"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M27.5 15C27.5 21.9035 21.9035 27.5 15 27.5C10.9104 27.5 7.2796 25.5361 4.99909 22.5M2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C19.0895 2.5 22.7204 4.46389 25.0009 7.5"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
						<path d="M5 22.5L3.75 26.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
						<path d="M25 7.5L26.25 3.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
					</svg>
				),
			},
		],
		"Sales Manager": [
			{
				key: "saleStatics",
				label: t("saleStatics"),
				icon: <SalesStaticsIcon />,
				children: [
					{
						key: "my-sales",
						label: t("MySales"),
					},
					{
						key: "debtors",
						label: t("Accounts receivable"),
					},
				],
			},
			{
				key: "sales",
				label: t("sales"),
				icon: (
					<svg
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18.75 8.75H11.25C9.86929 8.75 8.75 9.86929 8.75 11.25V18.75C8.75 20.1307 9.86929 21.25 11.25 21.25H18.75C20.1307 21.25 21.25 20.1307 21.25 18.75V11.25C21.25 9.86929 20.1307 8.75 18.75 8.75Z"
							stroke="white"
							strokeWidth="1.5"
						/>
						<path
							d="M16.25 12.5H13.75"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M27.5 15C27.5 21.9035 21.9035 27.5 15 27.5C10.9104 27.5 7.2796 25.5361 4.99909 22.5M2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C19.0895 2.5 22.7204 4.46389 25.0009 7.5"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
						<path d="M5 22.5L3.75 26.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
						<path d="M25 7.5L26.25 3.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
					</svg>
				),
				children: [
					{
						key: "sales-order",
						label: t("SalesOrder"),
					},
					{
						key: "shipped-sales",
						label: t("Shipped"),
					},
					{
						key: "finished-sales",
						label: t("finishedSales"),
					},
				],
			},
			{
				key: "clients",
				label: t("clients"),
				icon: <ClientIcon />,
			},
		],
		Supplier: [
			{
				key: "purchase-order",
				label: t("purchase-order"),
				icon: (
					<svg
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18.75 8.75H11.25C9.86929 8.75 8.75 9.86929 8.75 11.25V18.75C8.75 20.1307 9.86929 21.25 11.25 21.25H18.75C20.1307 21.25 21.25 20.1307 21.25 18.75V11.25C21.25 9.86929 20.1307 8.75 18.75 8.75Z"
							stroke="white"
							strokeWidth="1.5"
						/>
						<path
							d="M16.25 12.5H13.75"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M27.5 15C27.5 21.9035 21.9035 27.5 15 27.5C10.9104 27.5 7.2796 25.5361 4.99909 22.5M2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C19.0895 2.5 22.7204 4.46389 25.0009 7.5"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
						<path d="M5 22.5L3.75 26.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
						<path d="M25 7.5L26.25 3.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
					</svg>
				),
			},
			{
				key: "purchase-incoming",
				label: t("purchase-incoming"),
				icon: (
					<svg
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18.75 8.75H11.25C9.86929 8.75 8.75 9.86929 8.75 11.25V18.75C8.75 20.1307 9.86929 21.25 11.25 21.25H18.75C20.1307 21.25 21.25 20.1307 21.25 18.75V11.25C21.25 9.86929 20.1307 8.75 18.75 8.75Z"
							stroke="white"
							strokeWidth="1.5"
						/>
						<path
							d="M16.25 12.5H13.75"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M27.5 15C27.5 21.9035 21.9035 27.5 15 27.5C10.9104 27.5 7.2796 25.5361 4.99909 22.5M2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C19.0895 2.5 22.7204 4.46389 25.0009 7.5"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
						<path d="M5 22.5L3.75 26.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
						<path d="M25 7.5L26.25 3.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
					</svg>
				),
			},
			{
				key: "purchase-invoices",
				label: t("purchase-invoices"),
				icon: (
					<svg
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18.75 8.75H11.25C9.86929 8.75 8.75 9.86929 8.75 11.25V18.75C8.75 20.1307 9.86929 21.25 11.25 21.25H18.75C20.1307 21.25 21.25 20.1307 21.25 18.75V11.25C21.25 9.86929 20.1307 8.75 18.75 8.75Z"
							stroke="white"
							strokeWidth="1.5"
						/>
						<path
							d="M16.25 12.5H13.75"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M27.5 15C27.5 21.9035 21.9035 27.5 15 27.5C10.9104 27.5 7.2796 25.5361 4.99909 22.5M2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C19.0895 2.5 22.7204 4.46389 25.0009 7.5"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
						<path d="M5 22.5L3.75 26.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
						<path d="M25 7.5L26.25 3.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
					</svg>
				),
			},
			{
				key: "items",
				label: t("items"),
				icon: <TableOutlined style={{ fontSize: "25px", color: "white" }} />,
			},
			{
				key: "suppliers",
				label: t("suppliers"),
				icon: <UserOutlined style={{ fontSize: "25px", color: "white" }} />,
			},
		],
		Cashier: [
			{
				key: "sales",
				label: t("sales"),
				icon: <SalesIcon />,

				children: [
					{
						key: "sales-order",
						label: t("SalesOrder"),
					},
					{
						key: "sales",
						label: t("sales"),
					},
				],
			},
			{
				key: "payments",
				label: t("payments"),
				icon: <PaymentIcon />,
				children: [
					{
						key: "outgoing-payments",
						label: t("outgoingPayment"),
					},
					{
						key: "incoming-payments",
						label: t("incomingPayment"),
					},
				],
			},
			{
				key: "cash",
				label: t("kassa"),
				icon: <PaymentIcon />,
				children: [
					{
						key: "balance",
						label: t("balance"),
					},
					{
						key: "send-money",
						label: t("sendMoney"),
					},
				],
			},
		],
		all: [
			{
				key: "purchase-order",
				label: t("purchase-order"),
				icon: (
					<svg
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18.75 8.75H11.25C9.86929 8.75 8.75 9.86929 8.75 11.25V18.75C8.75 20.1307 9.86929 21.25 11.25 21.25H18.75C20.1307 21.25 21.25 20.1307 21.25 18.75V11.25C21.25 9.86929 20.1307 8.75 18.75 8.75Z"
							stroke="white"
							strokeWidth="1.5"
						/>
						<path
							d="M16.25 12.5H13.75"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M27.5 15C27.5 21.9035 21.9035 27.5 15 27.5C10.9104 27.5 7.2796 25.5361 4.99909 22.5M2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C19.0895 2.5 22.7204 4.46389 25.0009 7.5"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
						<path d="M5 22.5L3.75 26.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
						<path d="M25 7.5L26.25 3.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
					</svg>
				),
			},
			{
				key: "purchase-incoming",
				label: t("purchase-incoming"),
				icon: (
					<svg
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18.75 8.75H11.25C9.86929 8.75 8.75 9.86929 8.75 11.25V18.75C8.75 20.1307 9.86929 21.25 11.25 21.25H18.75C20.1307 21.25 21.25 20.1307 21.25 18.75V11.25C21.25 9.86929 20.1307 8.75 18.75 8.75Z"
							stroke="white"
							strokeWidth="1.5"
						/>
						<path
							d="M16.25 12.5H13.75"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M27.5 15C27.5 21.9035 21.9035 27.5 15 27.5C10.9104 27.5 7.2796 25.5361 4.99909 22.5M2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C19.0895 2.5 22.7204 4.46389 25.0009 7.5"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
						<path d="M5 22.5L3.75 26.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
						<path d="M25 7.5L26.25 3.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
					</svg>
				),
			},
			{
				key: "purchase-invoices",
				label: t("purchase-invoices"),
				icon: (
					<svg
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18.75 8.75H11.25C9.86929 8.75 8.75 9.86929 8.75 11.25V18.75C8.75 20.1307 9.86929 21.25 11.25 21.25H18.75C20.1307 21.25 21.25 20.1307 21.25 18.75V11.25C21.25 9.86929 20.1307 8.75 18.75 8.75Z"
							stroke="white"
							strokeWidth="1.5"
						/>
						<path
							d="M16.25 12.5H13.75"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M27.5 15C27.5 21.9035 21.9035 27.5 15 27.5C10.9104 27.5 7.2796 25.5361 4.99909 22.5M2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C19.0895 2.5 22.7204 4.46389 25.0009 7.5"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
						<path d="M5 22.5L3.75 26.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
						<path d="M25 7.5L26.25 3.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
					</svg>
				),
			},
			{
				key: "items",
				label: t("items"),
				icon: <TableOutlined style={{ fontSize: "25px", color: "white" }} />,
			},
			{
				key: "suppliers",
				label: t("suppliers"),
				icon: <UserOutlined style={{ fontSize: "25px", color: "white" }} />,
			},
		],
	};

	useEffect(() => {
		const menu = document.getElementById("menu");
		if (menu) {
			setMenuHeight(`${menu.scrollHeight}px`);
		}
	}, [collapse]);

	const toggleCollapsed = () => {
		dispatch(setCollapse(!collapse));
	};
	const onSelectMenuItem = (item) => {
		dispatch(setSelectedPath(item.key));
		navigate(`/${item.key}`);
	};

	const items = pages[role];

	return (
		<div className="flex">
			<div className={`${collapse ? "w-[80px]" : "w-[245px]"} h-${menuHeight} bg-[#0A4D68]`}>
				<div className="flex gap-10 pt-7">
					{collapse ? "" : <img src={logo} alt="" className="ml-5 w-[130px]" />}

					<button onClick={toggleCollapsed} className="bg-[#0A4D68] text-white text-2xl">
						{collapse ? <MenuUnfoldOutlined className="ml-8" /> : <MenuFoldOutlined />}
					</button>
				</div>
				<div className="mt-20">
					<Menu
						defaultOpenKeys={openMiniMenu}
						selectedKeys={[selectedPath]}
						mode="inline"
						inlineCollapsed={collapse}
						items={items}
						className="bg-transparent m-auto"
						theme="light"
						onSelect={onSelectMenuItem}
						onOpenChange={(v) => {
							dispatch(setOpenMiniMenu(v));
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default Menubar;
