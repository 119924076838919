import { Modal, Input, Button, message, Select } from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { http } from "../../services/http";

const CreteCustomerModal = ({ visible, onClose }) => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [name, setName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [address, setAddress] = useState("");
	const [email, setEmail] = useState("");
	const [passportSeries, setPassportSeries] = useState("");
	const [passportExpDate, setPassportExpDate] = useState("");
	const [passportGivenBy, setPassportGivenBy] = useState("");
	const [customerType, setCustomerType] = useState("");
	const [customerTypesData, setCustomerTypesData] = useState([]);

	const postData = async () => {
		setLoading(true);

		try {
			await http.post("api/businesspartners/mijozlar", {
				cardName: name,
				phoneNumber,
				address,
				email,
				passportSeries,
				passportExpDate,
				passportGivenBy,
				type: customerType,
			});
			message.success(t("sucCreated"));

			setTimeout(() => {
				// resetForm();
				onClose();
			}, 800);
		} catch (error) {
			message.error(t("errCreation") + error.message);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchBpType();
	}, []);

	const fetchBpType = async () => {
		try {
			const { data } = await http.get("api/businesspartners/bp-types");
			setCustomerTypesData(data);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Modal
			title={<h1 className="font-nunito  font-extrabold text-[#000000]">{t("addClient")}</h1>}
			visible={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="60%"
			// style={{ height: "70%" }}
			// bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
		>
			<div className="w-full px-2 sm:px-14">
				<div className="flex mt-4 items-center justify-between sm:mt-5">
					<div className=" flex flex-col gap-3 ">
						<p className="font-bold">{t("clientName")}</p>
						<Input
							type="text"
							onChange={(e) => {
								setName(e.target.value);
							}}
							className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>

					<div className=" flex flex-col gap-3 ">
						<p className="font-bold">{t("customerType")}</p>
						<Select
							type="text"
							onChange={(val) => {
								setCustomerType(val);
							}}
							className="flex h-10 w-[250px] items-center justify-center"
						>
							{customerTypesData.map((type, idx) => (
								<Select.Option key={idx} value={type.fldValue}>
									{`${type.descr}`}
								</Select.Option>
							))}
						</Select>
					</div>
				</div>
				<div className="flex items-center justify-between mt-5">
					<div className=" flex flex-col gap-3 ">
						<p className="font-bold">{t("Address")}</p>
						<Input
							type="text"
							onChange={(e) => {
								setAddress(e.target.value);
							}}
							className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
					<div className=" flex flex-col gap-3 ">
						<p className="font-bold">{t("email")}</p>
						<Input
							type="text"
							onChange={(e) => {
								setEmail(e.target.value);
							}}
							className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>
				<div className="flex items-center justify-between mt-5">
					<div className=" flex flex-col gap-3 ">
						<p className="font-bold"> {t("phoneNum")}</p>
						<Input
							type="text"
							onChange={(e) => {
								setPhoneNumber(e.target.value);
							}}
							className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
					<div className=" flex flex-col gap-3 ">
						<p className="font-bold">{t("passportSeries")}</p>
						<Input
							type="text"
							onChange={(e) => {
								setPassportSeries(e.target.value);
							}}
							className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>
				<div className="flex items-center justify-between mt-5">
					<div className=" flex flex-col gap-3 ">
						<p className="font-bold">{t("passportExpDate")}</p>
						<Input
							type="text"
							onChange={(e) => {
								setPassportExpDate(e.target.value);
							}}
							className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
					<div className=" flex flex-col gap-3 ">
						<p className="font-bold">{t("passportGivenBy")}</p>
						<Input
							type="text"
							onChange={(e) => {
								setPassportGivenBy(e.target.value);
							}}
							className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>
				<div className="flex items-center gap-10 mt-14 justify-between">
					<Button
						onClick={() => {
							onClose();
						}}
						className="w-full bg-red-600 h-[35px] rounded-lg text-white"
					>
						{t("cancel")}
					</Button>
					<Button
						onClick={postData}
						loading={loading}
						disabled={loading}
						className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
					>
						{t("add")}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default CreteCustomerModal;
