import React, { useEffect, useState } from "react";
import Layout from "../../../../components/Layout";
import { DatePicker, message, Input, Select, Table, Button, notification } from "antd";
import { http } from "../../../../services/http";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CreteCustomerModal from "../../../../components/Modal/createCustomerModal";

const CreateSales = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const employeeId = useSelector((state) => state.main.employeeId);
	const slpCode = useSelector((state) => state.main.salesPersonCode);

	const [clientsData, setClientsData] = useState([]);
	const [clientCode, setClientCode] = useState([]);
	const [date, setDate] = useState([]);

	// const [clientsData, setClientsData] = useState([]);

	const [itemsData, setItemsData] = useState([]);
	const [clientsLoading, setClientsLoading] = useState(true);
	const [itemsLoading, setItemsLoading] = useState(true);
	const [sendFile, setSendFile] = useState(false);

	const [isModalVisible, setIsModalVisible] = useState(false);

	const [tableData, setTableData] = useState([
		{
			key: "1",
			item: null,
			width: "",
			height: "",
			quantity: "",
			ugpName: "",
			price: "",
			currency: "USD",
			lineTotal: "",
		},
	]);

	const fetchItems = () => {
		http
			.get("api/items/get-items")
			.then((res) => {
				setItemsData(res.data.data);
			})
			.finally(() => {
				setItemsLoading(false);
			});
	};

	const fetchClients = () => {
		http
			.get("api/businesspartners/mijozlar")
			.then((res) => {
				setClientsData(res.data);
			})
			.finally(() => {
				setClientsLoading(false);
			});
	};

	const onSubmit = async (values) => {
		try {
			const data = {
				cardCode: clientCode,
				docDate: date,
				docDueDate: date,
				documentOwner: employeeId,
				salesPersonCode: slpCode,
				comments: values.comments,
				u_ChertejId: "",
				documentLines: tableData.map((row, index) => ({
					lineNum: index,
					itemCode: row.item,
					quantity: row.quantity,
					unitPrice: row.price,
					currency: row.currency,
				})),
			};

			await http.post(`api/sales/sotuv-buyurtmasi?needToConfirmFromBot=${sendFile}`, data);
			message.success(t("sucCreated"));
			setTimeout(() => {
				navigate("/sales-order");
			}, 800);
		} catch (error) {
			message.error(error.message);
		}
	};

	useEffect(() => {
		fetchClients();
		fetchItems();
	}, []);

	const addRow = () => {
		setTableData([
			...tableData,
			{
				key: Date.now().toString(),
				item: null,
				width: "",
				height: "",
				quantity: "",
				ugpName: "",
				price: "",
				lineTotal: "",
			},
		]);
	};

	const removeRow = (key) => {
		if (tableData.length > 1) {
			setTableData(tableData.filter((row) => row.key !== key));
		}
	};
	const handleDateChange = (date, dateString) => {
		setDate(dateString);
	};
	const handleItemChange = (value, record) => {
		const selectedItem = itemsData.find((item) => item.itemCode === value);
		if (selectedItem) {
			const updatedData = tableData.map((row) =>
				row.key === record.key
					? {
							...row,
							item: value,
							width: selectedItem.BWdth1 || "",
							height: selectedItem.BHeight1 || "",
							quantity: "",
							ugpName: selectedItem.ugpName || "",
							price: selectedItem.price || "",
							currency: selectedItem.currency || "USD",
							lineTotal: "",
						}
					: row
			);
			setTableData(updatedData);
		}
	};

	const columns = [
		{
			title: t("item"),
			dataIndex: "item",
			key: "item",
			fixed: "left",
			width: 200,
			render: (_, record) => (
				<Select
					loading={itemsLoading}
					options={itemsData}
					className={"w-full"}
					key={"itemCode"}
					fieldNames={{ label: "itemName", value: "itemCode" }}
					onChange={(value) => handleItemChange(value, record)}
					showSearch
					filterOption={(input, option) =>
						option?.itemName?.toLowerCase().includes(input.toLowerCase())
					}
				/>
			),
		},
		{
			title: t("width"),
			dataIndex: "width",
			key: "width",
			width: 150,
			render: (_, record) => (
				<Input
					value={record.width}
					className={"w-full"}
					onChange={(e) => {
						const updatedData = tableData.map((row) =>
							row.key === record.key ? { ...row, width: e.target.value } : row
						);
						setTableData(updatedData);
					}}
				/>
			),
		},
		{
			title: t("height"),
			dataIndex: "height",
			key: "height",
			width: 150,
			render: (_, record) => (
				<Input
					value={record.height}
					className={"w-full"}
					onChange={(e) => {
						const updatedData = tableData.map((row) =>
							row.key === record.key ? { ...row, height: e.target.value } : row
						);
						setTableData(updatedData);
					}}
				/>
			),
		},
		{
			title: t("quantity"),
			dataIndex: "quantity",
			key: "quantity",
			width: 150,
			render: (_, record) => (
				<Input
					value={record.quantity}
					className={"w-full"}
					onChange={(e) => {
						const updatedData = tableData.map((row) =>
							row.key === record.key
								? {
										...row,
										quantity: e.target.value,
										lineTotal: e.target.value * row.price || 0,
									}
								: row
						);
						setTableData(updatedData);
					}}
				/>
			),
		},
		{
			title: t("kv.m"),
			dataIndex: "ugpName",
			key: "ugpName",
			width: 150,
			render: (_, record) => (
				<Input
					value={record.ugpName}
					className={"w-full"}
					disabled
					onChange={(e) => {
						const updatedData = tableData.map((row) =>
							row.key === record.key ? { ...row, ugpName: e.target.value } : row
						);
						setTableData(updatedData);
					}}
				/>
			),
		},
		{
			title: t("pricePerKv"),
			dataIndex: "price",
			key: "price",
			width: 170,
			render: (_, record) => (
				<Input
					value={record.price}
					className={"w-full"}
					type={"number"}
					onChange={(e) => {
						const updatedData = tableData.map((row) =>
							row.key === record.key
								? {
										...row,
										price: e.target.value,
										lineTotal: e.target.value * row.quantity || 0,
									}
								: row
						);
						setTableData(updatedData);
					}}
				/>
			),
		},
		{
			title: t("totalMiqdor"),
			dataIndex: "lineTotal",
			key: "lineTotal",
			width: 170,
			render: (_, record) => (
				<Input
					value={record.lineTotal}
					className={"w-full"}
					disabled
					onChange={(e) => {
						const updatedData = tableData.map((row) =>
							row.key === record.key ? { ...row, lineTotal: e.target.value } : row
						);
						setTableData(updatedData);
					}}
				/>
			),
		},
		{
			title: t("services"),
			key: "services",
			render: (_, record) => (
				<div className="flex gap-2">
					{/* onClick={addRow} */}
					<Button type="primary">{t("services")}</Button>
				</div>
			),
		},
		{
			title: t("actions"),
			key: "actions",
			// fixed: "right",
			render: (_, record) => (
				<div className="flex gap-2">
					<Button type="primary" onClick={addRow}>
						+
					</Button>
					<Button
						danger
						type={"primary"}
						onClick={() => removeRow(record.key)}
						disabled={tableData.length === 1}
					>
						-
					</Button>
				</div>
			),
		},
	];

	return (
		<Layout>
			<div className={"p-7"}>
				<div className={"flex items-end"}>
					<div className="flex flex-col items-start gap-3 ">
						<p className="font-nunito">{t("Client")}</p>
						<Select
							loading={clientsLoading}
							options={clientsData}
							className="flex-1 h-10 w-[250px]"
							fieldNames={{ label: "cardName", value: "cardCode" }}
							onChange={(value) => setClientCode(value)}
						/>
					</div>
					<button
						className="w-[30px] h-8 bg-blue-700 text-white text-lg"
						onClick={() => setIsModalVisible(true)}
					>
						+
					</button>
				</div>
				<Table
					columns={columns}
					dataSource={tableData}
					pagination={false}
					className="border-1 mt-12 border-black"
					bordered
					scroll={{ x: "max-content" }}
				/>

				<div className="flex gap-10 mt-20 drop-shadow-lg bg-white p-5 rounded-lg">
					<div className="w-full flex flex-col  gap-6 ">
						<div className="flex flex-col items-start gap-3 ">
							<p className="font-nunito">{t("date")}</p>
							<DatePicker className={"w-full h-10"} onChange={handleDateChange} />
						</div>
						<div className="flex flex-col items-start gap-3">
							<p className="font-nunito">{t("totalForDocument")}</p>
							<Input
								type="text"
								value={`${tableData.reduce((acc, row) => acc + Number(row.lineTotal || 0), 0)} USD`}
								className="h-10 w-full "
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="flex flex-col items-start gap-3 w-full">
							<p className="font-nunito">{t("sendFile")}</p>
							<Input
								type="checkbox"
								value={sendFile}
								onChange={(e) => {
									setSendFile(e.target.checked);
								}}
								className="w-fit"
							/>
						</div>
					</div>

					<div className="w-full flex  h-full">
						<div className="flex flex-col items-start gap-3 w-full">
							<p className="font-nunito">{t("comments")}</p>
							<TextArea className={"w-full"} rows={9} />
						</div>
					</div>
				</div>
				<div className={"flex mt-10 w-full items-center gap-10"}>
					<Button
						onClick={() => {
							navigate(-1);
						}}
						type={"danger"}
						className="w-full bg-red-600 text-white"
					>
						{t("back")}
					</Button>
					<Button onClick={onSubmit} type={"primary"} className="w-full">
						{t("create")}
					</Button>
				</div>
				<CreteCustomerModal
					visible={isModalVisible}
					data={null}
					onClose={() => {
						fetchClients();
						setIsModalVisible(false);
					}}
				/>
			</div>
		</Layout>
	);
};

export default CreateSales;
